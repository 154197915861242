import { apiSlice, providesList } from "./apiSlice";
import { MachineContractDetailsWithRelations } from "./contractApi";
import { Job } from "./jobsApi";

// Interface for MachineType
export interface IMachineType {
	id: number;
	name: string;
	// machines: IMachine[]; // Assuming there's a corresponding IMachine interface
}

// Interface for MachineBrand
export interface IMachineBrand {
	id: number;
	name: string;
	models: IMachineModel[];
	// machines: IMachine[];
}

// Interface for MachineModel
export interface IMachineModel {
	id: number;
	name: string;
	// brand: IMachineBrand;
	brandId: number;
	// machines: IMachine[];
}

// Interface for Machine (if not already defined)
export interface IMachine {
	id: number;
	serialNumber: string;
	machineType: IMachineType;
	machineBrand: IMachineBrand;
	machineModel: IMachineModel;
}

// interface MachineState {
// 	id: number;
// 	machineId: number;
// 	toner_b?: string;
// 	toner_c?: string;
// 	toner_y?: string;
// 	toner_m?: string;
// 	toner_b_usage?: number;
// 	toner_c_usage?: number;
// 	toner_y_usage?: number;
// 	toner_m_usage?: number;
// 	updatedAt: Date;
// }

export interface Machine {
	id: number;
	type: IMachineType;
	typeId: number;
	serialNumber: string;
	brand: IMachineBrand;
	brandId: number;
	model: IMachineModel;
	modelId: number;
	active: boolean;
	sageId?: number;
	// machineState?: MachineState;
	jobs: Job[];
	officeId?: number;
	createdAt: Date;
	updatedAt: Date;
}

interface CreateTypePayload {
	type: string;
}

interface CreateBrandPayload {
	brand: string;
}

interface CreateModelPayload {
	brandId: number;
	model: string;
}

interface DeletableResponse {
	isDeletable: boolean;
	message?: string;
}

interface ValidationResponse {
	isValid: boolean;
	message?: string;
}

export const machineApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		createType: build.mutation<IMachineType, CreateTypePayload>({
			query: (payload) => ({
				url: "/machines/types/",
				method: "POST",
				body: payload,
			}),
		}),
		createBrand: build.mutation<IMachineType, CreateBrandPayload>({
			query: (payload) => ({
				url: "/machines/brands/",
				method: "POST",
				body: payload,
			}),
		}),
		createModel: build.mutation<IMachineType, CreateModelPayload>({
			query: (payload) => ({
				url: "/machines/models/",
				method: "POST",
				body: payload,
			}),
		}),
		getAllMachines: build.query<Machine[], void>({
			query: () => "/machines",
			providesTags: (result) => providesList(result, "Machine"),
		}),
		getAllUnassignedMachines: build.query<Machine[], { active?: boolean }>(
			{
				query: ({ active }) => {
					const params = new URLSearchParams();
					if (active) params.append("active", String(active));

					return {
						url: `/machines/unassigned?${params.toString()}`,
					};
				},
				providesTags: (result) => providesList(result, "Machine"),
			}
		),
		getMachineContractAndDetailsByMachineId: build.query<
			MachineContractDetailsWithRelations,
			number
		>({
			query: (machineId) => `/machines/${machineId}/contractDetails`,
			providesTags: (result, _error, id) =>
				result ? [{ type: "Contract", id }] : [],
		}),
		getMachineTypes: build.query<IMachineType[], void>({
			query: () => `/machines/types`,
		}),
		getMachineBrands: build.query<IMachineBrand[], void>({
			query: () => `/machines/brands`,
		}),
		getMachineModels: build.query<IMachineModel[], void>({
			query: () => `/machines/models`,
		}),
		isMachineDeletable: build.query<DeletableResponse, number>({
			query: (id) => `/machines/${id}/isDeletable`,
		}),
		isSageIdValid: build.query<
			ValidationResponse,
			{ machineId: number; sageId: number | string }
		>({
			query: (args) =>
				`/machines/${args.machineId}/isSageIdValid/${args.sageId}`,
		}),
		isSerialNumberValid: build.query<
			ValidationResponse,
			{ machineId: number; serialNumber: number | string }
		>({
			query: (args) =>
				`/machines/${args.machineId}/isSerialNumberValid/${args.serialNumber}`,
		}),
	}),
});

// export react hook
export const {
	useCreateTypeMutation,
	useCreateBrandMutation,
	useCreateModelMutation,
	useGetAllMachinesQuery,
	useGetMachineContractAndDetailsByMachineIdQuery,
	useLazyGetMachineContractAndDetailsByMachineIdQuery,
	useGetMachineTypesQuery,
	useGetMachineBrandsQuery,
	useGetMachineModelsQuery,
	useGetAllUnassignedMachinesQuery,
	useLazyIsMachineDeletableQuery,
	useLazyIsSageIdValidQuery,
	useLazyIsSerialNumberValidQuery,
} = machineApi;

// async queryFn(machineId, _queryApi, _extraOptions, fetchWithBQ) {
//   // Fetch machine contract details
//   const machineContractDetailsResult = await fetchWithBQ(`/machines/${machineId}/contractDetails`);
//   if (machineContractDetailsResult.error) {
//     return { error: machineContractDetailsResult.error as FetchBaseQueryError };
//   }
//   const machineContractDetails = machineContractDetailsResult.data as MachineContractAndDetailsWithDiscounts;

//   // Fetch contract details using contractId from machine contract details
//   const contractResult = await fetchWithBQ(`/contracts/${machineContractDetails.contractId}`);
//   if (contractResult.error) {
//     return { error: contractResult.error as FetchBaseQueryError };
//   }
//   const contract = contractResult.data as Contract;

//   // Combine results
//   const combinedResult: MachineContractDetailsWithDiscounts = {
//     ...machineContractDetails,
//     contract,
//     machineContractDetails.discounts
//   };
//   return { data: combinedResult };
// },

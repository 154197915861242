import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
	Card,
	CardBody,
	CardFooter,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	SimpleGrid,
	TabPanel,
	useToast,
} from "@chakra-ui/react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CustomerFormType } from "./CustomerValidationSchemas";
import CreatableSelect from "react-select/creatable";
import {
	useGetMachineTypesQuery,
	useGetMachineBrandsQuery,
	useGetMachineModelsQuery,
	useCreateTypeMutation,
	useCreateBrandMutation,
	useCreateModelMutation,
	useGetAllUnassignedMachinesQuery,
	useLazyIsMachineDeletableQuery,
	useLazyIsSageIdValidQuery,
	useLazyIsSerialNumberValidQuery,
} from "../../api/machineApi";
import { SingleValue } from "react-select";
import { formatMachineName } from "../../utils/helpers";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
	removeUnassignedMachine,
	setStepValidity,
} from "../customerFormSlice";
import Loading from "../../components/Loading";
import { ChangeEvent } from "react";
import { useAppSelector } from "../../app/hooks";

interface MachineFormProps {
	officeIndex: number;
	officeId: number;
}

const MachineForm = ({ officeIndex, officeId }: MachineFormProps) => {
	const dispatch = useDispatch();
	const toast = useToast();

	const { unassignedMachines } = useAppSelector(
		(state) => state.customerForm
	);

	const {
		control,
		formState: { errors },
		register,
		setValue,
		getValues,
		setError,
		trigger,
	} = useFormContext<CustomerFormType>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: `offices.${officeIndex}.machines`,
		keyName: "_id",
	});

	const { data: machineTypes, refetch: refetchMachineTypes } =
		useGetMachineTypesQuery();
	const { data: machineBrands, refetch: refetchBrands } =
		useGetMachineBrandsQuery();
	const { data: machineModels, refetch: refetchModels } =
		useGetMachineModelsQuery();
	const {
		refetch: refetchUnassignedMachines,
		isLoading: a,
		isFetching: b,
	} = useGetAllUnassignedMachinesQuery({});
	const [isMachineDeletable, { isLoading, isFetching }] =
		useLazyIsMachineDeletableQuery();
	const [
		isSageIdValid,
		{ isLoading: isSageIdValidLoading, isFetching: isSageIdValidFeching },
	] = useLazyIsSageIdValidQuery();
	const [
		isSerialNumberValid,
		{
			isLoading: isSerialNumberValidLoading,
			isFetching: isSerialNumberValidFeching,
		},
	] = useLazyIsSerialNumberValidQuery();

	const [createType] = useCreateTypeMutation();
	const [createBrand] = useCreateBrandMutation();
	const [createModel] = useCreateModelMutation();

	const handleCreateTypeOption = async (newValue: string, index: number) => {
		let newOption;
		try {
			newOption = await createType({ type: newValue }).unwrap();

			if (newOption) {
				toast({
					status: "success",
					// title: `${label} criado com sucesso`,
					title: `Tipo de máquina criado com sucesso`,
					description: `'${newOption.name}' foi adicionado como opção`,
					isClosable: true,
				});
				refetchMachineTypes();
				setValue(
					`offices.${officeIndex}.machines.${index}.typeId`,
					newOption.id.toString()
				);
				return;
			}

			throw Error();
		} catch (error) {
			toast({
				status: "error",
				title: `Erro na criação`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		}
	};

	const handleCreateBrandOption = async (
		newValue: string,
		index: number
	) => {
		let newOption;
		try {
			newOption = await createBrand({ brand: newValue }).unwrap();

			if (newOption) {
				toast({
					status: "success",
					title: `Marca criada com sucesso`,
					description: `'${newOption.name}' foi adicionado como opção`,
					isClosable: true,
				});
				refetchBrands();
				setValue(
					`offices.${officeIndex}.machines.${index}.brandId`,
					newOption.id.toString()
				);
				return;
			}

			throw Error();
		} catch (error) {
			toast({
				status: "error",
				title: `Erro na criação`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		}
	};

	const handleCreateModelOption = async (
		newValue: string,
		index: number
	) => {
		let newOption;
		try {
			newOption = await createModel({
				model: newValue,
				brandId: Number(
					getValues(
						`offices.${officeIndex}.machines.${index}.brandId`
					)
				),
			}).unwrap();

			if (newOption) {
				toast({
					status: "success",
					title: `Modelo criado com sucesso`,
					description: `'${newOption.name}' foi adicionado como opção`,
					isClosable: true,
				});
				refetchModels();
				setValue(
					`offices.${officeIndex}.machines.${index}.modelId`,
					newOption.id.toString()
				);
				return;
			}

			throw Error();
		} catch (error) {
			toast({
				status: "error",
				title: `Erro na criação`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		}
	};

	const handleMachineRemoval = async (id: number, index: number) => {
		try {
			if (id === 0 || !id) {
				remove(index);
				return;
			}
			const response = await isMachineDeletable(id).unwrap();
			if (!response.isDeletable) {
				toast({
					status: "warning",
					title: `Máquina não pode ser apagada`,
					description: response.message,
					isClosable: true,
				});

				return;
			}
			remove(index);
			await refetchUnassignedMachines();
			return;
		} catch (err) {
			return;
		}
	};

	const handleSageIdValidation = async (
		e: ChangeEvent<HTMLInputElement>,
		machineId: number,
		officeIndex: number,
		index: number
	) => {
		const fieldName =
			`offices.${officeIndex}.machines.${index}.sageId` as const;

		const isValid = await trigger(fieldName);
		if (!isValid) return;

		const sageId = e.target.value;
		if (!sageId) return;
		try {
			const response = await isSageIdValid({
				sageId: sageId,
				machineId,
			}).unwrap();

			if (!response.isValid) {
				toast({
					status: "warning",
					title: `Sage ID inválido`,
					description: response.message,
					isClosable: true,
				});

				setError(fieldName, {
					type: "400",
					message: "Sage ID já existe",
				});

				dispatch(setStepValidity(false));

				return;
			}
			dispatch(setStepValidity(true));
		} catch (error) {
			toast({
				status: "error",
				title: `Erro na validação`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		}
	};

	const handleSerialNumberValidation = async (
		e: ChangeEvent<HTMLInputElement>,
		machineId: number,
		officeIndex: number,
		index: number
	) => {
		const fieldName =
			`offices.${officeIndex}.machines.${index}.serialNumber` as const;

		const isValid = await trigger(fieldName);
		if (!isValid) return;

		const serialNumber = e.target.value;
		if (!serialNumber) return;
		try {
			const response = await isSerialNumberValid({
				serialNumber,
				machineId,
			}).unwrap();

			if (!response.isValid) {
				toast({
					status: "warning",
					title: `Numero de série inválido`,
					description: response.message,
					isClosable: true,
				});

				setError(fieldName, {
					type: "400",
					message: "Numero de série já existe",
				});

				dispatch(setStepValidity(false));

				return;
			}
			dispatch(setStepValidity(true));
		} catch (error) {
			toast({
				status: "error",
				title: `Erro na validação`,
				description: `Algo correu mal`,
				isClosable: true,
			});
		}
	};

	if (
		isLoading ||
		isFetching ||
		a ||
		b ||
		isSageIdValidLoading ||
		isSageIdValidFeching ||
		isSerialNumberValidLoading ||
		isSerialNumberValidFeching
	) {
		return <Loading />;
	}

	return (
		<TabPanel>
			<SimpleGrid spacing={4} templateColumns="repeat(3, 1fr)">
				{fields.map((field, index) => (
					<Card key={field._id}>
						<CardBody>
							<Flex flexDirection={"column"} gap={3}>
								{field.id === 0 && (
									<>
										<FormLabel margin={0}>
											Máquinas inativas
										</FormLabel>
										<Select
											options={
												unassignedMachines?.map(
													(machine) => ({
														label: formatMachineName(
															machine
														),
														value:
															machine.id.toString() ||
															"",
													})
												) || []
											}
											onChange={(option) => {
												const selectedMachine = (
													unassignedMachines ?? []
												).find(
													(machine) =>
														machine.id.toString() ===
														option?.value
												);

												// Find the selected machine by its ID
												const sanitized =
													selectedMachine
														? {
																...selectedMachine,
																typeId: selectedMachine.typeId.toString(),
																brandId:
																	selectedMachine.brandId.toString(),
																modelId:
																	selectedMachine.modelId.toString(),
																sageId:
																	selectedMachine.sageId?.toString() ??
																	"",
																officeId:
																	officeId,
														  }
														: null;

												if (sanitized) {
													setValue(
														`offices.${officeIndex}.machines.${index}`,
														sanitized,
														{
															shouldValidate:
																true,
														}
													);
													dispatch(
														removeUnassignedMachine(
															sanitized.id
														)
													);
												}
											}}
											placeholder={
												"Escolha uma maquina inativa"
											}
										/>
										<Divider my={3} />
									</>
								)}

								<FormControl
									isInvalid={
										!!errors?.offices?.[officeIndex]
											?.machines?.[index]?.typeId
											?.message
									}
									isRequired
								>
									<FormLabel>Tipo de máquina</FormLabel>
									<CreatableSelect
										{...register(
											`offices.${officeIndex}.machines.${index}.typeId` as const
										)}
										options={
											machineTypes?.map((type) => ({
												label: type.name,
												value: type.id.toString(),
											})) || []
										}
										value={(
											machineTypes?.map((type) => ({
												label: type.name,
												value: type.id.toString(),
											})) || []
										).find(
											(type) =>
												type.value ===
												getValues(
													`offices.${officeIndex}.machines.${index}.typeId`
												).toString()
										)}
										onChange={(
											option: SingleValue<{
												label: string;
												value: string;
											}>
										) => {
											setValue(
												`offices.${officeIndex}.machines.${index}.typeId`,
												option?.value || "",
												{ shouldValidate: true }
											);
										}}
										onCreateOption={(newValue: string) =>
											handleCreateTypeOption(
												newValue,
												index
											)
										}
										placeholder={
											"Escolha o tipo de máquina"
										}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[officeIndex]
												?.machines?.[index]?.typeId
												?.message
										}
									</FormErrorMessage>
								</FormControl>

								<Flex gap={2}>
									<FormControl
										isInvalid={
											!!errors?.offices?.[officeIndex]
												?.machines?.[index]?.brandId
												?.message
										}
										isRequired
									>
										<FormLabel>Marca</FormLabel>
										<CreatableSelect
											{...register(
												`offices.${officeIndex}.machines.${index}.brandId` as const
											)}
											options={
												machineBrands?.map((type) => ({
													label: type.name,
													value: type.id.toString(),
												})) || []
											}
											value={(
												machineBrands?.map((type) => ({
													label: type.name,
													value: type.id.toString(),
												})) || []
											).find(
												(type) =>
													type.value ===
													getValues(
														`offices.${officeIndex}.machines.${index}.brandId`
													).toString()
											)}
											onChange={(
												option: SingleValue<{
													label: string;
													value: string;
												}>
											) => {
												setValue(
													`offices.${officeIndex}.machines.${index}.brandId`,
													option?.value || "",
													{ shouldValidate: true }
												);
												setValue(
													`offices.${officeIndex}.machines.${index}.modelId`,
													""
												); // remove model because if brand changes models are going to be different
											}}
											onCreateOption={(
												newValue: string
											) =>
												handleCreateBrandOption(
													newValue,
													index
												)
											}
											placeholder={"Marca"}
										/>
										<FormErrorMessage>
											{
												errors.offices?.[officeIndex]
													?.machines?.[index]
													?.brandId?.message
											}
										</FormErrorMessage>
									</FormControl>

									<FormControl
										isInvalid={
											!!errors?.offices?.[officeIndex]
												?.machines?.[index]?.modelId
												?.message
										}
										isRequired
									>
										<FormLabel>Modelo</FormLabel>
										<CreatableSelect
											{...register(
												`offices.${officeIndex}.machines.${index}.modelId` as const
											)}
											options={
												machineModels
													?.filter((type) => {
														const brandId =
															getValues(
																`offices.${officeIndex}.machines.${index}.brandId`
															);
														return (
															type.brandId.toString() ===
															brandId.toString()
														);
													})
													.map((type) => ({
														label: type.name,
														value: type.id.toString(),
													})) || []
											}
											value={(
												machineModels?.map((type) => ({
													label: type.name,
													value: type.id.toString(),
												})) || []
											).find(
												(type) =>
													type.value ===
													getValues(
														`offices.${officeIndex}.machines.${index}.modelId`
													).toString()
											)}
											onChange={(
												option: SingleValue<{
													label: string;
													value: string;
												}>
											) => {
												setValue(
													`offices.${officeIndex}.machines.${index}.modelId`,
													option?.value || "",
													{ shouldValidate: true }
												);
											}}
											onCreateOption={(
												newValue: string
											) =>
												handleCreateModelOption(
													newValue,
													index
												)
											}
											placeholder={"Modelo"}
										/>
										<FormErrorMessage>
											{
												errors.offices?.[officeIndex]
													?.machines?.[index]
													?.modelId?.message
											}
										</FormErrorMessage>
									</FormControl>
								</Flex>

								<FormControl
									isInvalid={
										!!errors?.offices?.[officeIndex]
											?.machines?.[index]?.serialNumber
											?.message
									}
									isRequired
								>
									<FormLabel>Número de serie</FormLabel>
									<Input
										type="text"
										{...register(
											`offices.${officeIndex}.machines.${index}.serialNumber` as const
										)}
										onBlur={(e) =>
											handleSerialNumberValidation(
												e,
												getValues(
													`offices.${officeIndex}.machines.${index}.id`
												),
												officeIndex,
												index
											)
										}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[officeIndex]
												?.machines?.[index]
												?.serialNumber?.message
										}
									</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={
										!!errors?.offices?.[officeIndex]
											?.machines?.[index]?.sageId
											?.message
									}
									isRequired
								>
									<FormLabel>ID Sage</FormLabel>
									<Input
										type="number"
										{...register(
											`offices.${officeIndex}.machines.${index}.sageId` as const
										)}
										onBlur={(e) =>
											handleSageIdValidation(
												e,
												getValues(
													`offices.${officeIndex}.machines.${index}.id`
												),
												officeIndex,
												index
											)
										}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[officeIndex]
												?.machines?.[index]?.sageId
												?.message
										}
									</FormErrorMessage>
								</FormControl>
							</Flex>
						</CardBody>
						<CardFooter>
							{fields.length > 0 && (
								<IconButton
									aria-label="Remove object"
									icon={<MinusIcon />}
									variant="outline"
									onClick={() =>
										handleMachineRemoval(field.id, index)
									}
								/>
							)}
						</CardFooter>
					</Card>
				))}
				<Flex alignItems={"center"}>
					<IconButton
						aria-label="Add object"
						icon={<AddIcon />}
						onClick={() =>
							append({
								id: 0,
								serialNumber: "",
								typeId: "",
								brandId: "",
								modelId: "",
								active: true,
								sageId: "",
								officeId: officeId,
							})
						}
					/>
				</Flex>
			</SimpleGrid>
		</TabPanel>
	);
};

export default MachineForm;

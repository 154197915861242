import { Flex } from "@chakra-ui/react";
import { OptionType, selectCustomStyles } from "../../app/theme";
import MachineSelector from "../../components/MachineSelector";
import YearSelector from "../../components/YearSelector";
import { isAdmin } from "../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetAllClientsQuery } from "../../api/clientApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetOfficesAndMachinesByClientIdQuery } from "../../api/officeApi";
import { setClientId, setMachineId, setYear } from "./countsSlice";
import { useRef } from "react";
import { useGetMachineContractAndDetailsByMachineIdQuery } from "../../api/machineApi";
import Select, { SingleValue } from "react-select";

const CountsSelectors = () => {
	const dispatch = useAppDispatch();

	const { role, clientId: authClientId } = useAppSelector(
		(state) => state.auth
	);
	const { clientId, machineId, contract } = useAppSelector(
		(state) => state.counts
	);

	const { data: clientsData } = useGetAllClientsQuery(undefined, {
		skip: !isAdmin(role),
	});

	const { data: officesData } = useGetOfficesAndMachinesByClientIdQuery(
		authClientId ?? clientId ?? skipToken,
		{
			skip: (authClientId ?? clientId) === null,
			refetchOnMountOrArgChange: true,
		}
	);

	useGetMachineContractAndDetailsByMachineIdQuery(machineId ?? -1, {
		skip: !machineId,
		refetchOnMountOrArgChange: true,
	});

	const handleClientSelect = (clientOption: SingleValue<OptionType>) => {
		if (clientOption) dispatch(setClientId(Number(clientOption.value)));
	};

	const selectRef = useRef<HTMLSelectElement>(null);
	const handleMachineSelect = async (machineId: number) => {
		dispatch(setMachineId(machineId));
		const currentYear = new Date().getFullYear();
		if (selectRef.current) {
			selectRef.current.value = currentYear.toString();
			dispatch(setYear(currentYear));
		}
	};

	const handleYearSelect = (year: number) => {
		if (year && machineId && contract?.id) {
			dispatch(setYear(year));
		}
	};

	return (
		<Flex direction={"column"}>
			{isAdmin(role) && clientsData && (
				<Select
					options={clientsData.map((client) => {
						return {
							label: client.name,
							value: client.id,
						};
					})}
					onChange={handleClientSelect}
					styles={selectCustomStyles}
					placeholder="Selecione um cliente"
				/>
			)}
			<MachineSelector
				offices={officesData ?? []}
				onMachineSelect={handleMachineSelect}
			/>
			{isAdmin(role) && (
				<YearSelector
					onYearSelect={handleYearSelect}
					selectRef={selectRef}
				/>
			)}
		</Flex>
	);
};

export default CountsSelectors;

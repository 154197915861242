import {
	createApi,
	fetchBaseQuery,
	FetchArgs,
	FetchBaseQueryError,
	BaseQueryFn,
} from "@reduxjs/toolkit/query/react";
import { ApiRefreshResponse } from "../utils/constants";
import { refreshAuth, logout } from "../features/authSlice";

export function providesList<
	R extends { id: string | number }[],
	T extends string
>(resultsWithIds: R | undefined, tagType: T) {
	return resultsWithIds
		? [
				{ type: tagType, id: "LIST" },
				...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
		  ]
		: [{ type: tagType, id: "LIST" }];
}

const baseQuery = fetchBaseQuery({
	// baseUrl: `${process.env.REACT_APP_BACKEND_BASE_URL}/api`,
	baseUrl: `https://api.assisteprint.com/api`,
	credentials: "include",
	mode: "cors",
});

const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result && result.error && result.error.status === 401) {
		// TODO: Check if we need to try and refresh or if were are just unautorized

		const refreshResult = await baseQuery(
			"/auth/refresh",
			api,
			extraOptions
		);

		// If the refresh was successful
		if ((refreshResult.data as ApiRefreshResponse)?.accessToken) {
			// Dispatch the new token to the store
			api.dispatch(
				refreshAuth(refreshResult.data as ApiRefreshResponse)
			);

			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logout());
		}
	}
	return result;
};

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	tagTypes: [
		"Client",
		"Contract",
		"Machine",
		"CopyCount",
		"CountsBilling",
		"MissingCounts",
	],
	endpoints: () => ({}),
});

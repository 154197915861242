import { Text, Stack } from "@chakra-ui/react";

interface TextWithLabelProps {
	text: string;
	label: string;
}

const TextWithLabel = ({ text, label }: TextWithLabelProps) => {
	return (
		<Stack gap={1}>
			<Text fontSize="sm" color="gray.600">
				{label}
			</Text>
			<Text fontWeight={500}>{text}</Text>
		</Stack>
	);
};

export default TextWithLabel;

import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
	SimpleGrid,
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	CardFooter,
	IconButton,
} from "@chakra-ui/react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CustomerFormType } from "./CustomerValidationSchemas";

const CustomerStep3 = () => {
	const {
		control,
		formState: { errors },
		register,
	} = useFormContext<CustomerFormType>();

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: `users`,
		keyName: "_id",
	});

	return (
		<SimpleGrid spacing={4} templateColumns="repeat(3, 1fr)">
			{fields.map((field, index) => (
				<Card key={field._id}>
					<CardBody>
						<Flex flexDirection={"column"} gap={3}>
							<FormControl
								isInvalid={
									!!errors?.users?.[index]?.name?.message
								}
								isRequired
							>
								<FormLabel>Nome</FormLabel>
								<Input
									type="text"
									{...register(
										`users.${index}.name` as const
									)}
								/>
								<FormErrorMessage>
									{errors.users?.[index]?.name?.message}
								</FormErrorMessage>
							</FormControl>

							<FormControl
								isInvalid={
									!!errors?.users?.[index]?.email?.message
								}
								isRequired
							>
								<FormLabel>Email</FormLabel>
								<Input
									type="text"
									{...register(
										`users.${index}.email` as const
									)}
								/>
								<FormErrorMessage>
									{errors.users?.[index]?.email?.message}
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</CardBody>
					<CardFooter>
						{fields.length > 0 && (
							<IconButton
								aria-label="Remove object"
								icon={<MinusIcon />}
								variant="outline"
								onClick={() => remove(index)}
							/>
						)}
					</CardFooter>
				</Card>
			))}
			<Flex alignItems={"center"}>
				<IconButton
					aria-label="Add object"
					icon={<AddIcon />}
					onClick={() =>
						append({
							id: 0,
							name: "",
							email: "",
						})
					}
				/>
			</Flex>
		</SimpleGrid>
	);
};

export default CustomerStep3;

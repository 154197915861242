export const customerFormSteps = [
	{
		title: "Info",
		description: "Primeiro",
		fields: ["name", "email", "telephone", "nif", "sageId", "active"],
	},
	{
		title: "Escritórios",
		description: "Segundo",
		fields: [
			"offices.*.name",
			"offices.*.email",
			"offices.*.address",
			"offices.*.location",
			"offices.*.telephone",
			"offices.*.mainOffice",
		],
	},
	{
		title: "Maquinas",
		description: "Terceiro",
		fields: [
			"offices.**.machines.*.typeId",
			"offices.**.machines.*.brandId",
			"offices.**.machines.*.modelId",
			"offices.**.machines.*.serialNumber",
			"offices.**.machines.*.sageId",
		],
	},
	{
		title: "Utilizadores",
		description: "Quarto",
		fields: ["users.*.name", "users.*.email"],
	},
	{ title: "Confirmar", description: "Quinto", fields: [] },
];

import React from "react";
import {
	Avatar,
	Box,
	BoxProps,
	Text,
	CloseButton,
	Drawer,
	DrawerContent,
	Flex,
	FlexProps,
	Image,
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	VStack,
	useColorModeValue,
	useDisclosure,
	Divider,
	useToast,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
	FiHome,
	FiTrendingUp,
	FiMenu,
	FiChevronDown,
	FiDollarSign,
	FiUserPlus,
	FiUsers,
	FiFilePlus,
	FiFile,
} from "react-icons/fi";
import { Bs123 } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { Role } from "../utils/constants";
import { useLogoutMutation } from "../api/authApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { getErrorMessage } from "../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { useAppSelector } from "../app/hooks";

interface LinkItemProps {
	name: string;
	icon: IconType;
	target: string;
}

interface NavItemProps extends FlexProps {
	icon: IconType;
	target: string;
	children: React.ReactNode;
}

interface MobileProps extends FlexProps {
	onOpen: () => void;
	role: string;
	name: string;
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
	role: string;
}

const LinkItemsAdmin: Array<LinkItemProps> = [
	{ name: "Pagina Inicial", icon: FiHome, target: "/admin" },
	{ name: "Faturação", icon: FiDollarSign, target: "/admin/billing" },
	{ name: "Contagens", icon: Bs123, target: "/client/counts" },
	{ name: "Lista Clientes", icon: FiUsers, target: "/admin/clients" },
	{
		name: "Criar Cliente",
		icon: FiUserPlus,
		target: "/admin/create/client",
	},
	{
		name: "Lista Contratos",
		icon: FiFile,
		target: "/admin/contracts",
	},
	{
		name: "Criar Contrato",
		icon: FiFilePlus,
		target: "/admin/create/contracts",
	},
];

const LinkItemsUser: Array<LinkItemProps> = [
	{ name: "Pagina Inicial", icon: FiHome, target: "/client" },
	{ name: "Contagens", icon: FiTrendingUp, target: "/client/counts" },
];

const SidebarContent = ({ onClose, role, ...rest }: SidebarProps) => {
	const LinkItems = role === Role.Admin ? LinkItemsAdmin : LinkItemsUser;

	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue("gray.100", "gray.900")}
			borderRight="1px"
			borderRightColor={useColorModeValue("gray.200", "gray.700")}
			w={{ base: "full", md: 60 }}
			pos="fixed"
			h="full"
			{...rest}
		>
			<Flex
				h="20"
				alignItems="center"
				mx="3"
				justifyContent="space-between"
			>
				<Image
					w={{ base: "320px" }}
					src="/assets/images/logo.png"
					alt="Assisteprint Logo"
				/>
				<CloseButton
					display={{ base: "flex", md: "none" }}
					onClick={onClose}
				/>
			</Flex>
			<Divider
				borderColor={"white"}
				display={{ base: "flex", md: "none" }}
			/>
			{LinkItems.map((link) => (
				<NavItem key={link.name} icon={link.icon} target={link.target}>
					{link.name}
				</NavItem>
			))}
		</Box>
	);
};

const NavItem = ({ icon, target, children, ...rest }: NavItemProps) => {
	const location = useLocation();

	return (
		<Box
			as="a"
			href={target}
			style={{ textDecoration: "none" }}
			_focus={{ boxShadow: "none" }}
		>
			<Flex
				align="center"
				p="4"
				mx="4"
				mb="1"
				borderRadius="lg"
				role="group"
				cursor="pointer"
				bg={location.pathname === target ? "red.500" : "transparent"}
				color={location.pathname === target ? "white" : "black"}
				_hover={{
					bg: location.pathname === target ? "" : "gray.400",
					color: "white",
				}}
				{...rest}
			>
				{icon && (
					<Icon
						mr="4"
						fontSize="16"
						_groupHover={{
							color: "white",
						}}
						as={icon}
					/>
				)}
				{children}
			</Flex>
		</Box>
	);
};

const MobileNav = ({ onOpen, role, name, ...rest }: MobileProps) => {
	const toast = useToast();

	const [logout] = useLogoutMutation();

	const handleLogout = () => {
		const promise = logout().unwrap();

		toast.promise(promise, {
			loading: {
				title: "A encerrar sessão",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: { title: "Sessão encerrada com sucesso" },
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro ao encerrar sessão",
					description: errorMessage,
				};
			},
		});
	};

	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 4 }}
			height="20"
			alignItems="center"
			bg={"white"}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue("gray.200", "gray.700")}
			justifyContent={{ md: "flex-end" }}
			gap={{ base: 5 }}
			{...rest}
		>
			<IconButton
				display={{ base: "flex", md: "none" }}
				onClick={onOpen}
				variant="outline"
				aria-label="open menu"
				icon={<FiMenu />}
			/>

			<Image
				display={{ base: "flex", md: "none" }}
				w={"270px"}
				src="/assets/images/logo.png"
				alt="Assisteprint Logo"
			/>

			<HStack spacing={{ base: "0", md: "6" }}>
				{/* notifications */}
				{/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        /> */}
				<Flex alignItems={"center"}>
					<Menu>
						<MenuButton
							py={2}
							transition="all 0.3s"
							_focus={{ boxShadow: "none" }}
						>
							<HStack>
								<Avatar size={"sm"} name={name} />
								<VStack
									display={{ base: "none", md: "flex" }}
									alignItems="flex-start"
									spacing="1px"
									ml="2"
								>
									<Text fontSize="sm">{name}</Text>
									<Text fontSize="xs" color="gray.600">
										{role}
									</Text>
								</VStack>
								<Box display={{ base: "none", md: "flex" }}>
									<FiChevronDown />
								</Box>
							</HStack>
						</MenuButton>
						<MenuList
							bg={useColorModeValue("white", "gray.900")}
							borderColor={useColorModeValue(
								"gray.200",
								"gray.700"
							)}
						>
							{/* <MenuItem>Profile</MenuItem> */}
							<MenuItem onClick={handleLogout}>
								Terminar Sessão
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
		</Flex>
	);
};

const Navbar = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { role, name } = useAppSelector((state) => state.auth);

	return (
		<Box bg={"white"}>
			<SidebarContent
				onClose={() => onClose}
				display={{ base: "none", md: "block" }}
				role={role}
			/>
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full"
			>
				<DrawerContent>
					<SidebarContent onClose={onClose} role={role} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav onOpen={onOpen} role={role} name={name ?? ""} />
		</Box>
	);
};

export default Navbar;

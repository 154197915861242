import {
	Box,
	GridItem,
	Step,
	StepDescription,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
} from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import ContractForm from "../../features/contractForm/ContractForm";
import { contractFormSteps } from "../../features/contractForm/ContractFormConfig";
import { useAppSelector } from "../../app/hooks";

const ContractFormPage = () => {
	const { step } = useAppSelector((state) => state.contractForm);

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Stepper size="lg" index={step} px={20} colorScheme="red">
					{contractFormSteps.map((step, index) => (
						<Step key={index}>
							<StepIndicator>
								<StepStatus
									complete={<StepIcon />}
									incomplete={<StepNumber />}
									active={<StepNumber />}
								/>
							</StepIndicator>

							<Box flexShrink="0">
								<StepTitle>{step.title}</StepTitle>
								<StepDescription>
									{step.description}
								</StepDescription>
							</Box>

							<StepSeparator />
						</Step>
					))}
				</Stepper>
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 10 }} pt={20}>
				<ContractForm />
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default ContractFormPage;

export const contractFormSteps = [
	{
		title: "Info",
		description: "Primeiro",
		fields: [
			"contract.clientId",
			"contract.startDate",
			"contract.endDate",
			"contract.billingType",
		],
	},
	{
		title: "Maquinas",
		description: "Segundo",
		fields: [
			"machinesContractDetails.*.machineId",
			"machinesContractDetails.*.contractType",
			"machinesContractDetails.*.initialCountsBlack",
			"machinesContractDetails.*.initialCountsColor",
			"machinesContractDetails.*.blackCopyUnitPrice",
			"machinesContractDetails.*.colorCopyUnitPrice",
			"machinesContractDetails.*.monthlyFee",
		],
	}, // fields unnecessary
	{
		title: "Descontos",
		description: "Terceiro",
		fields: [
			"discounts.*.discountType",
			"discounts.*.startDate",
			"discounts.*.endDate",
			"discounts.*.machineIds",
			"discounts.*.blackCopyDiscountAmount",
			"discounts.*.colorCopyDiscountAmount",
			"discounts.*.usedBlackCopies",
			"discounts.*.usedColorCopies",
		],
	},
	{ title: "Confirmar", description: "Quinto", fields: [] },
];

export const billingTypes = [
	{
		label: "Faturação por contrato",
		value: "PER_CONTRACT",
	},
	{
		label: "Faturação por escritório",
		value: "PER_OFFICE",
	},
	{
		label: "Faturação por máquina",
		value: "PER_MACHINE",
	},
	{
		label: "Fatura única por cliente",
		value: "PER_CLIENT",
	},
];

export const contractType = [
	{ label: "grenke", value: "grenke" },
	{ label: "vendida", value: "vendida" },
	{ label: "aluguer", value: "alugada" },
];

export const discountTypes = [
	{ label: "Unico", value: "ONE_TIME" },
	{ label: "Mensal", value: "MONTHLY" },
	{ label: "Até limite", value: "UNTIL_LIMIT" },
];

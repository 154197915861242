import {
	Flex,
	GridItem,
	Input,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";
import ClientsTable from "../../components/admin/ClientsTable";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import { Search2Icon } from "@chakra-ui/icons";
import { useMemo, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { useGetAllClientsQuery } from "../../api/clientApi";
import Loading from "../../components/Loading";

const ClientsPage = () => {
	const searchNameRef = useRef<HTMLInputElement>(null);
	const [name, setName] = useState<string>("");

	const {
		data: clientsData,
		isFetching,
		isLoading,
		isSuccess,
	} = useGetAllClientsQuery();

	const onChangeClientName = debounce(() => {
		setName(searchNameRef.current?.value || "");
	}, 500);

	const filteredClients = useMemo(() => {
		if (isSuccess && name !== "") {
			return clientsData.filter((client) =>
				client.name.toLowerCase().includes(name.trim().toLowerCase())
			);
		}
		return clientsData || [];
	}, [name, isSuccess]);

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Flex>
					<InputGroup w={"300px"} my={5}>
						<InputLeftElement pointerEvents="none">
							<Search2Icon color="gray.300" />
						</InputLeftElement>
						<Input
							ref={searchNameRef}
							type="text"
							placeholder="Nome"
							onChange={onChangeClientName}
						/>
					</InputGroup>
				</Flex>
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				{(isLoading || isFetching) && <Loading />}
				{isSuccess && <ClientsTable clients={filteredClients} />}
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default ClientsPage;

import {
	Stack,
	Heading,
	Badge,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	Tooltip,
} from "@chakra-ui/react";
import { formatDateDayAndMonth } from "../../utils/dateUtils";
import { isAdmin } from "../../utils/helpers";
import { useAppSelector } from "../../app/hooks";
import { useGetCountsBillingQuery } from "../../api/billingApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import Loading from "../../components/Loading";

const CountsBilling = () => {
	const { role } = useAppSelector((state) => state.auth);
	const { contract } = useAppSelector((state) => state.counts);

	const { data: billingData, isLoading } = useGetCountsBillingQuery(
		contract?.id ?? skipToken,
		{
			skip: !isAdmin(role),
			refetchOnMountOrArgChange: true,
		}
	);

    if(isLoading)
        return <Loading fillViewPort={false} />

	return (
		<>
			{billingData && contract?.id && (
				<Stack gap={5}>
					<Heading as={"h3"} size={"lg"}>
						A Faturar{" "}
						<Tooltip
							label="Faturação total para o cliente relativa ao mês passado. Nota: Este total não reflete o tipo de faturação de cada contrato é apenas um total de todas as contagens para o cliente selecionado."
							fontSize="sm"
							hasArrow
						>
							<QuestionOutlineIcon boxSize={"20px"} mr={3} />
						</Tooltip>
						{billingData.isComplete ? (
							<Badge colorScheme="green" p="1">
								Pronto
							</Badge>
						) : (
							<Badge colorScheme="yellow" p="1">
								Incompleto
							</Badge>
						)}
					</Heading>
					<Stat>
						<StatLabel>Copias Preto</StatLabel>
						<StatNumber>
							{billingData.blackBilling.toFixed(2)} €
						</StatNumber>
						<StatHelpText>
							{formatDateDayAndMonth(
								billingData.billingDate.startDate
							)}{" "}
							-{" "}
							{formatDateDayAndMonth(
								billingData.billingDate.endDate
							)}
						</StatHelpText>
					</Stat>
					<Stat>
						<StatLabel>Copias Cor</StatLabel>
						<StatNumber>
							{billingData.colorBilling.toFixed(2)} €
						</StatNumber>
						<StatHelpText>
							{formatDateDayAndMonth(
								billingData.billingDate.startDate
							)}{" "}
							-{" "}
							{formatDateDayAndMonth(
								billingData.billingDate.endDate
							)}
						</StatHelpText>
					</Stat>
					<Stat>
						<StatLabel>
							Total (Preto + Cor + Mensalidades)
						</StatLabel>
						<StatNumber>
							{billingData.totalBilling.toFixed(2)} €
						</StatNumber>
						<StatHelpText>
							{formatDateDayAndMonth(
								billingData.billingDate.startDate
							)}{" "}
							-{" "}
							{formatDateDayAndMonth(
								billingData.billingDate.endDate
							)}
						</StatHelpText>
					</Stat>
				</Stack>
			)}
		</>
	);
};

export default CountsBilling;

import { apiSlice } from "./apiSlice";
import { Machine } from "./machineApi";

export enum DiscountType {
	ONE_TIME = "ONE_TIME",
	MONTHLY = "MONTHLY",
	UNTIL_LIMIT = "UNTIL_LIMIT",
}

export interface Discount {
	id: number;
	type: DiscountType;
	blackCopyDiscountAmount: number;
	colorCopyDiscountAmount: number;
	usedBlackCopies: number;
	usedColorCopies: number;
	startDate: Date;
	endDate: Date;
	machineContractDetailsId: number;
	usageDate: Date;
	createdAt: Date;
	updatedAt: Date;
}

export type DiscountWithMachines = Discount & { machines?: Machine[] };

export const discountApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		// getAllUsers: build.query<Discount[], void>({
		//   query: () => "/users",
		// }),
	}),
});

// export react hook
// export const {} = discountApi;

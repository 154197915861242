import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { contractApi } from "../../api/contractApi";

type ContractForm = {
	step: number;
	// discounts: DiscountFormType | undefined;
	// contract: ContractWithRelations | undefined;
	// contract: ContractFormType | undefined;
};

const initialState: ContractForm = {
	step: 0,
	// discounts: undefined,
	// contract: undefined,
	// contract: undefined,
};

const contractFormSlice = createSlice({
	name: "contractForm",
	initialState: initialState,
	reducers: {
		setStep(state, action: PayloadAction<number>) {
			state.step = action.payload;
		},
	},
	extraReducers: (builder) => {
		// builder.addMatcher(
		// 	contractApi.endpoints.getContractById.matchFulfilled,
		// 	(state, { payload }) => {
		// 		const discounts = payload.machinesContractDetails?.flatMap(
		// 			(detail) =>
		// 				(detail.discounts || []).map((discount) => ({
		// 					...discount,
		// 					machineIds: [detail.machineId.toString()],
		// 				}))
		// 		);

		// 		console.log(discounts);

		// 		const x =
		// 			discounts?.reduce<DiscountFormType[]>((acc, current) => {
		// 				const existingDiscount = acc.find(
		// 					(discount) => discount.id === current.id
		// 				);

		// 				if (existingDiscount) {
		// 					// If the discount already exists, merge the machineIds
		// 					existingDiscount.machineIds = [
		// 						...existingDiscount.machineIds!,
		// 						...current.machineIds,
		// 					];
		// 					// existingDiscount.startDate =
		// 					// 	(existingDiscount.startDate &&
		// 					// 		new Date(
		// 					// 			contract.startDate
		// 					// 		).toLocaleDateString("pt-PT", {
		// 					// 			day: "numeric",
		// 					// 			month: "numeric",
		// 					// 			year: "numeric",
		// 					// 		})) ||
		// 					// 	"";
		// 					// existingDiscount.endDate =
		// 					// 	(existingDiscount.endDate &&
		// 					// 		new Date(
		// 					// 			contract.endDate
		// 					// 		).toLocaleDateString("pt-PT", {
		// 					// 			day: "numeric",
		// 					// 			month: "numeric",
		// 					// 			year: "numeric",
		// 					// 		})) ||
		// 					// 	"";
		// 				} else {
		// 					// If it's a new discount, add it to the accumulator
		// 					acc.push({
		// 						...current,
		// 						discountType: current.type,
		// 						startDate: current.startDate.toString(),
		// 						endDate: current.endDate.toString(),
		// 					});
		// 				}

		// 				return acc;
		// 			}, []) || [];

		// 		console.log(x);
		// 	}
		// );

		// builder.addMatcher(
		// 	contractApi.endpoints.getContractById.matchFulfilled,
		// 	(state, { payload }) => {
		// 		state.contract = {
		// 			contract: {
		// 				id: payload.id,
		// 				startDate: payload.startDate
		// 					? new Date(payload.startDate)
		// 							.toISOString()
		// 							.slice(0, 10)
		// 					: "",
		// 				endDate: payload.endDate
		// 					? new Date(payload.endDate)
		// 							.toISOString()
		// 							.slice(0, 10)
		// 					: "",
		// 				clientId: payload.client?.id.toString() || "",
		// 				billingType: payload.billingType,
		// 			},
		// 			machinesContractDetails:
		// 				payload.machinesContractDetails?.map((detail) => ({
		// 					...detail,
		// 					machineId: detail.machineId.toString(),
		// 				})) || [],
		// 			discounts:
		// 				payload.machinesContractDetails
		// 					?.flatMap((detail) =>
		// 						(detail.discounts || []).map((discount) => ({
		// 							...discount,
		// 							machineIds: [detail.machineId.toString()],
		// 						}))
		// 					)
		// 					.reduce<DiscountFormType[]>((acc, current) => {
		// 						const existingDiscount = acc.find(
		// 							(discount) => discount.id === current.id
		// 						);

		// 						if (existingDiscount) {
		// 							// If the discount already exists, merge the machineIds
		// 							existingDiscount.machineIds = [
		// 								...(existingDiscount.machineIds || []),
		// 								...current.machineIds,
		// 							];
		// 							existingDiscount.startDate = "";
		// 							existingDiscount.endDate = "";
		// 						} else {
		// 							// If it's a new discount, add it to the accumulator
		// 							acc.push({
		// 								...current,
		// 								startDate: new Date(current.startDate)
		// 									.toISOString()
		// 									.slice(0, 10),
		// 								endDate: new Date(current.endDate)
		// 									.toISOString()
		// 									.slice(0, 10),
		// 								discountType: current.type,
		// 							});
		// 						}

		// 						return acc;
		// 					}, []) || [],
		// 		};
		// 	}
		// );

		builder.addMatcher(
			contractApi.endpoints.createContract.matchFulfilled,
			() => initialState
		);
		builder.addMatcher(
			contractApi.endpoints.updateContract.matchFulfilled,
			() => initialState
		);
	},
});

export const { setStep } = contractFormSlice.actions;

export default contractFormSlice.reducer;

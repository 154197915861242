import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clientApi } from "../api/clientApi";
import { Machine, machineApi } from "../api/machineApi";
import { CustomerFormType } from "./customerForm/CustomerValidationSchemas";

type CustomerForm = {
	step: number;
	unassignedMachines: Machine[];
	customerData: CustomerFormType;
	isStepValid: boolean;
};

const initialState: CustomerForm = {
	step: 0,
	unassignedMachines: [],
	customerData: {
		id: 0,
		name: "",
		email: "",
		telephone: "",
		nif: "",
		sageId: "",
		active: "true",
		offices: [],
		users: [],
	},
	isStepValid: true,
};

const customerFormSlice = createSlice({
	name: "customerForm",
	initialState,
	reducers: {
		setStep(state, action: PayloadAction<number>) {
			state.step = action.payload;
		},
		removeUnassignedMachine(state, action: PayloadAction<number>) {
			state.unassignedMachines = state.unassignedMachines.filter(
				(machine) => machine.id !== action.payload
			);
		},
		setStepValidity(state, { payload }: PayloadAction<boolean>) {
			state.isStepValid = payload;
		},
	},
	extraReducers(builder) {
		builder.addMatcher(
			clientApi.endpoints.createClient.matchFulfilled,
			() => initialState
		);
		builder.addMatcher(
			clientApi.endpoints.updateClientById.matchFulfilled,
			() => initialState
		);
		builder.addMatcher(
			machineApi.endpoints.getAllUnassignedMachines.matchFulfilled,
			(state, { payload }) => {
				state.unassignedMachines = payload;
			}
		);
		builder.addMatcher(
			clientApi.endpoints.getClientById.matchFulfilled,
			(state, { payload }) => {
				if (state.step > 0) return; // Avoid replacing current form on refecth

				state.customerData = {
					...payload,
					nif: payload.nif?.toString() || "",
					sageId: payload.sageId?.toString() || "",
					active: payload.active.toString(),
					offices: payload.offices?.map((office) => ({
						...office,
						postalCode: office.postalCode,
						mainOffice: office.mainOffice ? "true" : "false",
						machines:
							office.machines?.map((machine) => ({
								...machine,
								sageId: machine.sageId?.toString() || "",
								typeId: machine.typeId.toString(),
								brandId: machine.brandId.toString(),
								modelId: machine.modelId.toString(),
								officeId: machine.officeId || office.id,
							})) || [],
					})) || [
						{
							id: 0,
							name: "",
							email: "",
							address: "",
							location: "",
							telephone: "",
							latitude: "",
							longitude: "",
							postalCode: "",
							mainOffice: "false",
							machines: [],
						},
					],
					users: payload.users || [{ id: 0, name: "", email: "" }],
				};
			}
		);
	},
});

export const { setStep, removeUnassignedMachine, setStepValidity } =
	customerFormSlice.actions;

export default customerFormSlice.reducer;

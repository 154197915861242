import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { apiSlice } from "../api/apiSlice";
import clientSlice from "../features/clientSlice";
import authSlice from "../features/authSlice";
import countsSlice from "../features/counts/countsSlice";
import contractFormSlice from "../features/contractForm/contractFormSlice";
import customerFormSlice from "../features/customerFormSlice";

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		auth: authSlice,
		client: clientSlice,
		counts: countsSlice,
		customerForm: customerFormSlice,
		contractForm: contractFormSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>(); // Export a hook that can be reused to resolve types

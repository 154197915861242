import {
  Container,
  Stack,
  Flex,
  Text,
  Image
} from "@chakra-ui/react";
import LandingPageGridLayout from "../layouts/LandingPageGridLayout";

const LandingPage = () => {
  return (
    <LandingPageGridLayout>
      <Container maxW={"5xl"}>
        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          {/* <Heading
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Assisteprint
          </Heading> */}
          <Flex w={"full"} justifyContent={"center"}>
            {/* <Illustration height={{ sm: '24rem', lg: '28rem' }} mt={{ base: 12, sm: 16 }} /> */}
            <Image
              src="/assets/images/introduction.png"
              alt="Introduction Image"
            />
          </Flex>
          <Text color={"gray.500"} maxW={"3xl"}>
            Novo website ainda em construcao!
            <br />
            Novidades no futuro
          </Text>
          {/* <Stack spacing={6} direction={"row"}>
            <Button
              rounded={"full"}
              px={6}
              colorScheme={"orange"}
              bg={"orange.400"}
              _hover={{ bg: "orange.500" }}
            >
              Get started
            </Button>
            <Button rounded={"full"} px={6}>
              Learn more
            </Button>
          </Stack> */}
        </Stack>
      </Container>
    </LandingPageGridLayout>
  );
};

export default LandingPage;

import { GridItem } from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import ContractTable from "../../components/admin/ContractTable";

const ContractPage = () => {
	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<ContractTable />
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default ContractPage;

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	GridItem,
	Heading,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import {
	useDeleteClientMutation,
	useGetClientByIdQuery,
} from "../../api/clientApi";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import Loading from "../../components/Loading";
import { getErrorMessage } from "../../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";

const CustomerDetailsPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const toast = useToast();
	const { id } = useParams();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef<HTMLButtonElement>(null);

	const {
		data: client,
		isLoading: clientIsLoading,
		isFetching: clientIsFecthing,
	} = useGetClientByIdQuery(
		id
			? {
					id: Number(id),
					includeOffices: true,
			  }
			: skipToken
	);

	const [deleteClient, { isSuccess }] = useDeleteClientMutation();

	useEffect(() => {
		if (isSuccess) navigate("/admin/clients");
	}, [isSuccess, navigate]);

	if (clientIsLoading || clientIsFecthing) {
		return <Loading />;
	}

	if (!client) {
		return <div>No data available.</div>;
	}

	const handleBackClick = () => {
		if (location.key === "default") {
			navigate("/admin/clients");
		} else {
			navigate(-1);
		}
	};

	const handleClientDelete = (id: number) => {
		let promise;
		try {
			promise = deleteClient(id).unwrap();

			toast.promise(promise, {
				loading: {
					title: "A apagar o cliente",
					description: "Aguarde enquanto processamos o seu pedido.",
				},
				success: {
					title: "Cliente apagado com sucesso",
				},
				error: (err: Error) => {
					const errorMessage = getErrorMessage(
						err as FetchBaseQueryError | SerializedError
					);
					return {
						title: "Erro ao apagar o Cliente",
						description: errorMessage,
					};
				},
			});
		} catch (error) {
			console.debug("Delete error:", error);
		}
	};

	return (
		<UserLoggedGridLayout>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Apagar cliente
						</AlertDialogHeader>

						<AlertDialogBody>
							Tem a certeza que pretende apagar o cliente?
							<strong>Operação não pode ser revertida!</strong>
							<Text fontSize={"xs"}>
								(Serão apagados contratos, contagens, descontos
								etc.)
							</Text>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancelar
							</Button>
							<Button
								colorScheme="red"
								onClick={() => {
									handleClientDelete(client.id);
									onClose();
								}}
								ml={3}
								variant={"outline"}
							>
								Apagar
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Button onClick={handleBackClick} variant={"ghost"}>
					Back
				</Button>
				<Flex justify={"space-between"} alignItems={"center"}>
					<Heading my={6}>{client.name}</Heading>
					<Flex gap={4}>
						<Button
							variant={"secondary"}
							onClick={() =>
								navigate(`/admin/edit/client/${client.id}`)
							}
						>
							Editar
						</Button>
						<Button
							variant={"solid"}
							onClick={onOpen}
						>
							Apagar
						</Button>
					</Flex>
				</Flex>
			</GridItem>
			<GridItem
				colStart={{ base: 1, md: 2 }}
				colEnd={{ base: 4, md: 10 }}
			></GridItem>
		</UserLoggedGridLayout>
	);
};

export default CustomerDetailsPage;

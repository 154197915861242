import {
	Card,
	CardBody,
	CardHeader,
	Flex,
	Stat,
	StatLabel,
	StatNumber,
} from "@chakra-ui/react";
import { useGetClientCountStatsQuery } from "../../api/statsApi";
import { FiUsers } from "react-icons/fi";

const ClientsStat = () => {
	const { data: clientCount } = useGetClientCountStatsQuery();

	return (
		<Card direction={"row"}>
			<CardHeader>
				<Flex alignItems={"center"}>
					<FiUsers size={"50px"} />
				</Flex>
			</CardHeader>

			<CardBody>
				<Stat>
					<StatLabel>Clientes</StatLabel>
					<StatNumber>{clientCount?.totalClients ?? "-"}</StatNumber>
				</Stat>
			</CardBody>
		</Card>
	);
};

export default ClientsStat;

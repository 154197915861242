import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Stack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Text,
	IconButton,
	Flex,
} from "@chakra-ui/react";
import { FiEdit3 } from "react-icons/fi";
import {
	useGetLastCountByContractIdAndMachineIdQuery,
	useGetAllCountsByContractIdAndMachineIdYearlyQuery,
} from "../../api/copyCountsApi";
import { useAppDispatch } from "../../app/store";
import { formatDateMonth } from "../../utils/dateUtils";
import { isAdmin, isClient } from "../../utils/helpers";
import { setBlackCount, setEditing, setColorCount } from "./countsSlice";
import { useAppSelector } from "../../app/hooks";
import AddNewCount from "./AddNewCount";
import { skipToken } from "@reduxjs/toolkit/query";

const CountsTable = () => {
	const dispatch = useAppDispatch();

	const { role } = useAppSelector((state) => state.auth);
	const {
		year,
		machineId,
		contract,
		updateCountData,
		editingId,
		contractNotFoundError,
		isContractInactive,
	} = useAppSelector((state) => state.counts);

	useGetLastCountByContractIdAndMachineIdQuery(
		contract && machineId && isClient(role)
			? { contractId: contract.id, machineId: machineId }
			: skipToken,
		{ refetchOnMountOrArgChange: true }
	);
	const { data: lastCounts = [] } =
		useGetAllCountsByContractIdAndMachineIdYearlyQuery(
			contract && machineId && year && isAdmin(role)
				? {
						contractId: contract.id,
						machineId: machineId,
						year: year,
				  }
				: skipToken,
			{
				refetchOnMountOrArgChange: true,
				// selectFromResult: (response) => {
				// 	response.data;
				// },
			}
		);

	if (!machineId || !year || contractNotFoundError || isContractInactive)
		return null;

	return (
		<TableContainer mt={8}>
			<Table
				variant={
					lastCounts.length > 0 && !editingId ? "striped" : "simple"
				}
			>
				<Thead>
					<Tr>
						<Th>Mes</Th>
						<Th>
							Copias
							<br />
							Preto
						</Th>
						{isAdmin(role) && (
							<Th>
								Preco Copia
								<br />
								Preto (€)
							</Th>
						)}
						{isAdmin(role) && (
							<Th>
								Preco Total
								<br />
								Copias Preto (€)
							</Th>
						)}
						<Th>
							Copias Preto <br />
							Descontadas
						</Th>
						<Th>
							Copias
							<br />
							Cor
						</Th>
						{isAdmin(role) && (
							<Th>
								Preco Copia
								<br />
								Cor (€)
							</Th>
						)}
						{isAdmin(role) && (
							<Th>
								Preco Total
								<br />
								Copias Cor (€)
							</Th>
						)}
						<Th>
							Copias Cor <br />
							Descontadas
						</Th>
						<Th>Total (€)</Th>
					</Tr>
				</Thead>
				<Tbody>
					{/* Last counts fetched from the api */}
					{lastCounts.map((count, index) => (
						<Tr key={count.id}>
							<Td>{formatDateMonth(new Date(count.date))}</Td>
							<Td>
								<Stack direction={"row"} alignItems={"center"}>
									{editingId && count.id === editingId ? (
										<NumberInput
											defaultValue={count.blackCount}
											min={0}
											max={10000000}
											w={"120px"}
											onChange={(_, valueAsNumber) => {
												dispatch(
													setBlackCount(
														valueAsNumber
													)
												);
											}}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									) : (
										<>
											<Flex>
												{count.blackCount}&nbsp;
												<Text
													fontSize="xs"
													color="gray.500"
												>
													(+
													{count.blackDiff})
												</Text>
											</Flex>
											{isAdmin(role) &&
												index ===
													lastCounts.length - 1 && (
													<IconButton
														aria-label="Editar contagem"
														icon={<FiEdit3 />}
														variant="ghost"
														colorScheme="gray"
														size={"sm"}
														onClick={() =>
															dispatch(
																setEditing(
																	count.id
																)
															)
														}
													/>
												)}
										</>
									)}
								</Stack>
							</Td>
							{isAdmin(role) && (
								<Td>{count.blackCopyUnitPrice} €</Td>
							)}
							{isAdmin(role) && (
								<Td>
									{count.blackCopiesTotalPrice.toFixed(2)} €
								</Td>
							)}
							<Td>{count.blackCopiesDiscounted}</Td>
							<Td>
								<Stack direction={"row"} alignItems={"center"}>
									{editingId && count.id === editingId ? (
										<NumberInput
											defaultValue={count.colorCount}
											min={0}
											max={10000000}
											w={"120px"}
											onChange={(_, valueAsNumber) => {
												dispatch(
													setColorCount(
														valueAsNumber
													)
												);
											}}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									) : (
										<>
											<Flex>
												{count.colorCount}&nbsp;
												<Text
													fontSize="xs"
													color="gray.500"
												>
													(+
													{count.colorDiff})
												</Text>
											</Flex>
											{/* Cant update because the  */}
											{isAdmin(role) &&
												index ===
													lastCounts.length - 1 && (
													<IconButton
														aria-label="Editar contagem"
														icon={<FiEdit3 />}
														variant="ghost"
														colorScheme="gray"
														size={"sm"}
														onClick={() =>
															dispatch(
																setEditing(
																	count.id
																)
															)
														}
													/>
												)}
										</>
									)}
								</Stack>
							</Td>
							{isAdmin(role) && (
								<Td>{count.colorCopyUnitPrice} €</Td>
							)}
							{isAdmin(role) && (
								<Td>
									{count.colorCopiesTotalPrice.toFixed(2)} €
								</Td>
							)}
							<Td>{count.colorCopiesDiscounted}</Td>
							<Td>
								{editingId &&
								count.id === editingId &&
								updateCountData
									? updateCountData.total.toFixed(2)
									: count.total.toFixed(2)}{" "}
								€
							</Td>
						</Tr>
					))}

					{/* Total counts row for admin only */}
					{isAdmin(role) && lastCounts.length !== 0 && (
						<Tr
							fontWeight="bold"
							bg="gray.200"
							borderTop="2px solid"
							borderColor="gray.300"
						>
							<Td>Total</Td>
							<Td>
								{lastCounts.reduce((partialSum, copyCount) => {
									const blackDiff = copyCount.blackDiff;
									return (
										partialSum +
										(blackDiff !== undefined
											? blackDiff
											: 0)
									);
								}, 0)}
							</Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td>
								{lastCounts.reduce((partialSum, copyCount) => {
									const colorDiff = copyCount.colorCount;
									return (
										partialSum +
										(colorDiff !== undefined
											? colorDiff
											: 0)
									);
								}, 0)}
							</Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td>
								{lastCounts
									.reduce(
										(partialSum, a) =>
											partialSum + a.total,
										0
									)
									.toFixed(2)}{" "}
								€
							</Td>
						</Tr>
					)}

					{/* Submit row for client and admins */}
					<AddNewCount />
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default CountsTable;

/* Admin Count Submit (SUPER USER)   should not be necessary for now */
/* {isAdmin(role) &&
						newAdminCountData.toggle &&
						machineContractDetails && (
							<Tr fontWeight="bold" bg="red.50">
								<Td>
									<Select
										onChange={(e) =>
											dispatch(
												setAdminCountMonth(
													e.target.value
												)
											)
										}
									>
										<option value="January">
											Janeiro
										</option>
										<option value="February">
											Fevereiro
										</option>
										<option value="March">Março</option>
										<option value="April">Abril</option>
										<option value="May">Maio</option>
										<option value="June">Junho</option>
										<option value="July">Julho</option>
										<option value="August">Agosto</option>
										<option value="September">
											Setembro
										</option>
										<option value="October">
											Outubro
										</option>
										<option value="November">
											Novembro
										</option>
										<option value="December">
											Dezembro
										</option>
									</Select>
								</Td>
								<Td>
									<NumberInput
										defaultValue={0}
										min={0}
										max={10000000}
										w={"120px"}
										onChange={(_, valueAsNumber) => {
											dispatch(
												setAdminBlackCount(
													valueAsNumber
												)
											);
										}}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Td>
								<Td>
									<NumberInput
										min={0}
										max={10000000}
										w={"120px"}
										onChange={(_, valueAsNumber) => {
											dispatch(
												setAdminBlackPrice(
													valueAsNumber
												)
											);
										}}
										defaultValue={
											machineContractDetails.blackCopyUnitPrice ||
											0
										}
										step={0.001}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Td>
								<Td></Td>
								<Td>
									<NumberInput
										defaultValue={0}
										min={0}
										max={10000000}
										w={"120px"}
										onChange={(_, valueAsNumber) => {
											dispatch(
												setAdminColorCount(
													valueAsNumber
												)
											);
										}}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Td>
								<Td>
									<NumberInput
										min={0}
										max={10000000}
										w={"120px"}
										onChange={(_, valueAsNumber) => {
											dispatch(
												setAdminColorPrice(
													valueAsNumber
												)
											);
										}}
										defaultValue={
											machineContractDetails.colorCopyPrice ||
											0
										}
										step={0.001}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Td>
								<Td></Td>
								<Td>
									<NumberInput
										defaultValue={0}
										min={-1}
										max={10000000}
										w={"120px"}
										onChange={(_, valueAsNumber) => {
											dispatch(
												setAdminTotal(valueAsNumber)
											);
										}}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</Td>
							</Tr>
						)} */

/* <Center>
				{isAdmin(role) && !newAdminCountData.toggle && (
					<IconButton
						mt={10}
						aria-label="Admin Privileges"
						icon={<AddIcon />}
						onClick={() => dispatch(setAdminNewCountToggle(true))}
					/>
				)}
			</Center> */

import { Box, Grid, GridItem } from "@chakra-ui/react";
import Footer from "../components/Footer";
import LandingNavbar from "../components/LandingNavbar";

interface LandingPageGridLayoutProps {
	children: React.ReactNode;
}

const LandingPageGridLayout = ({ children }: LandingPageGridLayoutProps) => {
	return (
		<Box>
			<Grid templateColumns="repeat(12, 1fr)" gap={"20px"}>
				<GridItem w="100%" colSpan={12}>
					<LandingNavbar />
				</GridItem>

				{/* Main Content */}
				<GridItem w="100%" h="100vh" colSpan={12}>
					{children}
				</GridItem>

				<GridItem w="100%" colSpan={12}>
					<Footer />
				</GridItem>
			</Grid>
		</Box>
	);
};

export default LandingPageGridLayout;

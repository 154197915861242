import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

// Encrypt data and store it in a cookie
export const encryptAndStore = (key: string, data: unknown) => {
	const encryptedData = CryptoJS.AES.encrypt(
		JSON.stringify(data),
		process.env.REACT_APP_ENCRYPTION_KEY || ""
	).toString();
	Cookies.set(key, encryptedData, {
		expires: Number(process.env.REACT_APP_COOKIE_EXPIRATION) || 7,
	});
};

// Retrieve encrypted data from a cookie and decrypt it
export const retrieveAndDecrypt = (key: string) => {
	const encryptedData = Cookies.get(key);
	if (encryptedData) {
		const decryptedBytes = CryptoJS.AES.decrypt(
			encryptedData,
			process.env.REACT_APP_ENCRYPTION_KEY as string
		);
		const decryptedData = JSON.parse(
			decryptedBytes.toString(CryptoJS.enc.Utf8)
		);
		return decryptedData;
	}
	return null;
};

// Remove the cookie from the browser
export const removeFromCookies = (key: string): void => {
	try {
		Cookies.remove(key);
	} catch (error) {
		console.error(`Error removing cookie: ${error}`);
	}
};

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../app/hooks";

type Props = {
  allowedRoles: string[];
};

const RequireAuth = (props: Props) => {
  const location = useLocation();
  const { isAuthenticated, role } = useAppSelector((state) => state.auth);

  const userHasRequiredRole =
    role && props.allowedRoles.includes(role) ? true : false;

  return isAuthenticated ? (
    userHasRequiredRole ? (
      <Outlet />
    ) : (
      <p>Access Denied</p>
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;

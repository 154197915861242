import * as Yup from "yup";
import { DiscountType } from "../../api/discountsApi";
import { BillingType } from "../../api/contractApi";

const ContractInfoSchema = Yup.object().shape({
	id: Yup.number().required().default(0),
	startDate: Yup.string().required("Data de inicio é obrigatório"),
	endDate: Yup.string().required("Data de fim é obrigatório"),
	clientId: Yup.string().required("É obrigatório escolher um cliente"),
	billingType: Yup.mixed<BillingType>()
		.oneOf(Object.values(BillingType), "Opção com erro")
		.required("É obrigatório escolher um tipo de faturação"),
});

const MachineContractDetailsSchema = Yup.object().shape({
	id: Yup.number().required().default(0),
	machineId: Yup.string().required("Escolher uma máquina é obrigatório"),
	contractType: Yup.string().required("Tipo de contrato é obrigatório"),
	initialCountsBlack: Yup.number()
		.required("Contagem inicial a preto é obrigatório")
		// .matches(/^[0-9]+$/, "Contagem deve conter apenas números")
		.typeError("Contagem inválida")
		.min(0, "Apenas numeros maiores que 0"),
	initialCountsColor: Yup.number()
		.required("Contagem inicial a cor é obrigatório")
		// .matches(/^[0-9]+$/, "Contagem deve conter apenas números")
		.typeError("Contagem inválida")
		.min(0, "Apenas numeros maiores que 0"),
	blackCopyUnitPrice: Yup.number()
		.required("Obrigatório")
		.typeError("Contagem inválida")
		.min(0, "Apenas numeros maiores que 0"),
	colorCopyUnitPrice: Yup.number()
		.required("Obrigatório")
		.typeError("Contagem inválida")
		.min(0, "Apenas numeros maiores que 0"),
	monthlyFee: Yup.number()
		.required("Obrigatório")
		.typeError("Contagem inválida")
		.min(0, "Apenas numeros maiores que 0"),
	contractId: Yup.number().required("Contrato é obrigatório"),
});

const DiscountSchema = Yup.object().shape({
	id: Yup.number().required().default(0),
	discountType: Yup.string().required("Tipo de desconto é obrigatório"),
	startDate: Yup.string().when("discountType", {
		is: DiscountType.ONE_TIME,
		then: (schema) => schema.required("Data de inicio é obrigatória"),
	}),
	endDate: Yup.string().when("discountType", {
		is: DiscountType.ONE_TIME,
		then: (schema) => schema.required("Data de fim é obrigatória"),
	}),
	machineIds: Yup.array()
		.of(Yup.string().required())
		.min(1, "Deve escolher pelo menos uma máquina"),
	blackCopyDiscountAmount: Yup.number()
		.integer()
		.min(0)
		.required()
		.typeError("Valor inválido"),
	colorCopyDiscountAmount: Yup.number()
		.integer()
		.min(0)
		.required()
		.typeError("Valor inválido"),
	// usedBlackCopies: Yup.number()
	// 	.integer()
	// 	.min(0)
	// 	.required()
	// 	.typeError("Valor inválido"),
	// usedColorCopies: Yup.number()
	// 	.integer()
	// 	.min(0)
	// 	.required()
	// 	.typeError("Valor inválido"),
	// discountUsed: Yup.array()
	// 	.of(
	// 		// Add the appropriate schema for the CopyCountDiscount items
	// 		Yup.object().shape({
	// 			// Define the fields based on your CopyCountDiscount interface
	// 		})
	// 	)
	// 	.required(),
});

export const ContractFormSchema = Yup.object().shape({
	contract: ContractInfoSchema,
	machinesContractDetails: Yup.array()
		.of(MachineContractDetailsSchema)
		.min(1, "At least one machine is required"),
	discounts: Yup.array().of(DiscountSchema),
});

export type ContractFormType = Yup.InferType<typeof ContractFormSchema>;
export type DiscountFormType = Yup.InferType<typeof DiscountSchema>;

import { useEffect, useRef, useState } from "react";
import {
	ContractWithClient,
	useGetAllContractsQuery,
	useLazyGetContractByIdQuery,
} from "../../api/contractApi";
import {
	CheckCircleIcon,
	SmallCloseIcon,
	SearchIcon,
	Search2Icon,
} from "@chakra-ui/icons";
import {
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Flex,
	IconButton,
	Stack,
	Spinner,
	Input,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { GetExtraArgsWithId } from "../../utils/constants";
import debounce from "lodash.debounce";

const ContractTable = () => {
	const navigate = useNavigate();

	const searchByClientNameRef = useRef<HTMLInputElement>(null);

	const [filteredContracts, setFilteredContracts] = useState<
		ContractWithClient[]
	>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(500);

	const [getContractById] = useLazyGetContractByIdQuery();

	const { data, isFetching, isLoading } = useGetAllContractsQuery({
		includeClients: true,
	});

	useEffect(() => {
		if (data) setFilteredContracts(data);
	}, [data]);

	if (isLoading || isFetching) {
		return (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				sx={{ width: "100%", height: "100vh" }}
			>
				<Spinner />
			</Stack>
		);
	}

	if (!data) {
		return <div>No data available.</div>;
	}

	const handleContractEdit = async (contractId: number): Promise<void> => {
		const params: GetExtraArgsWithId = {
			id: contractId,
			includeClients: true,
			includeMachinesContractDetails: true,
			includeDiscounts: true,
		};
		const result = await getContractById(params);

		if (result.isSuccess) {
			navigate(`/admin/edit/contract/${contractId}`);
		}
	};

	const onSearchForClienteName = debounce(() => {
		setFilteredContracts(
			data.filter((contract) =>
				searchByClientNameRef.current != null
					? contract.client?.name
							.toLowerCase()
							.includes(
								searchByClientNameRef.current.value
									.trim()
									.toLowerCase()
							)
					: false
			)
		);
	}, 800);

	return (
		<>
			<Flex>
				<InputGroup w={"300px"} mt={10} marginBottom={10}>
					<InputLeftElement pointerEvents="none">
						<Search2Icon color="gray.300" />
					</InputLeftElement>
					<Input
						ref={searchByClientNameRef}
						type="text"
						placeholder="Nome do cliente"
						onChange={onSearchForClienteName}
					/>
				</InputGroup>
			</Flex>
			<TableContainer>
				<Table variant="striped" colorScheme="gray">
					<Thead>
						<Tr>
							<Th>ID</Th>
							<Th>Nome do Cliente</Th>
							<Th>Data de inicio</Th>
							<Th>Data de fim</Th>
							<Th>Ativo</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{(rowsPerPage > 0
							? filteredContracts.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: filteredContracts
						).map(({ id, client, startDate, endDate, active }) => (
							<Tr key={id}>
								<Td>{id}</Td>
								<Td>{client && client.name}</Td>
								<Td>
									{new Date(startDate).toLocaleDateString(
										"pt-PT",
										{
											day: "numeric",
											month: "numeric",
											year: "numeric",
										}
									)}
								</Td>
								<Td>
									{new Date(endDate).toLocaleDateString(
										"pt-PT",
										{
											day: "numeric",
											month: "numeric",
											year: "numeric",
										}
									)}
								</Td>
								<Td>
									{active ? (
										<CheckCircleIcon color="green" />
									) : (
										<SmallCloseIcon color="red" />
									)}
								</Td>
								<Td>
									<Flex gap={3}>
										<IconButton
											aria-label="Ver detalhes do cliente"
											onClick={() =>
												navigate(
													`/admin/contracts/${id}/details`
												)
											}
										>
											<SearchIcon color={"white"} />
										</IconButton>
										<IconButton
											aria-label="Editar Contrato"
											variant={"outline"}
											colorScheme="blue"
											onClick={() =>
												handleContractEdit(id)
											}
										>
											<FiEdit2 />
										</IconButton>
									</Flex>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
};

export default ContractTable;

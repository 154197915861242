import { apiSlice } from "./apiSlice";

type ApiLoginResponse = {
	accessToken: string;
	refreshToken: string;
};

type ApiLoginRequest = {
	email: string;
	password: string;
};

type ApiRegisterRequest = {
	name: string;
	email: string;
	password: string;
	token: string;
};

export const authApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		register: build.mutation<ApiLoginResponse, ApiRegisterRequest>({
			query: ({ name, email, password, token }) => ({
				url: "users/register",
				method: "POST",
				body: { name, email, password, token },
			}),
		}),
		login: build.mutation<ApiLoginResponse, ApiLoginRequest>({
			query: ({ email, password }) => ({
				url: "auth/login",
				method: "POST",
				body: { email, password },
			}),
		}),
		logout: build.mutation<null, void>({
			query: () => ({
				url: "auth/logout",
				method: "POST",
			}),
		}),
	}),
});

// export react hook
export const { useLoginMutation, useLogoutMutation, useRegisterMutation } =
	authApi;

import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";

interface UserLoggedGridLayoutProps {
	children: React.ReactNode;
}

const UserLoggedLayout = ({ children }: UserLoggedGridLayoutProps) => {
	return (
		<Box minH="100vh" bg={"white"}>
			<Navbar />
			<Box ml={{ base: 0, md: 60 }} p="4" h={"100%"}>
				{/* Content */}
				{children}
			</Box>
		</Box>
	);
};

export default UserLoggedLayout;

import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Role } from "./constants";
import { DiscountType } from "../api/discountsApi";
import { Machine } from "../api/machineApi";
import { BillingType } from "../api/contractApi";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
	error: unknown
): error is FetchBaseQueryError {
	return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
	error: unknown
): error is { message: string } {
	return (
		typeof error === "object" &&
		error != null &&
		("message" in error || "data" in error) &&
		typeof (error as any).message === "string"
	);
}

export interface ApiErrorResponse {
	status: number;
	data: { message: string; errors: { [k: string]: string[] } };
}

export function isApiResponse(error: unknown): error is ApiErrorResponse {
	return (
		typeof error === "object" &&
		error != null &&
		"status" in error &&
		typeof (error as any).status === "number"
	);
}

// Utility function to combine loading states
export const combineLoadingStates = (...loadingStates: boolean[]): boolean => {
	return loadingStates.some((state) => state);
};

// Utility function to combine error states
export const combineErrorStates = (
	...errorStates: (FetchBaseQueryError | SerializedError | undefined)[]
): FetchBaseQueryError | SerializedError | undefined => {
	return errorStates.find((error) => error !== undefined);
};

// export const getErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
//   if ('status' in error) {
//     console.log(error);

//     // Handle FetchBaseQueryError
//     if (error.data && typeof error.data === 'object' && 'error' in error.data) {
//       return `Error: ${error.status} - ${error.data.error}`;
//     } else {
//       return `Error: ${error.status} - ${JSON.stringify(error.data)}`;
//     }
//   } else {
//     // Handle SerializedError
//     return `Error: ${error.message}`;
//   }
// };

export const getErrorMessage = (
	error: FetchBaseQueryError | SerializedError | undefined
): string => {
	if (!error) {
		return "An unknown error occurred.";
	}

	if ("status" in error) {
		// Handle FetchBaseQueryError
		const data = error.data as { message?: string };
		if (data && data.message) {
			return data.message;
		}
		return `Error: ${error.status} - An unknown error occurred.`;
	} else {
		// Handle SerializedError
		return `Error: ${error.message}`;
	}
};

export const isAdmin = (role: Role) => {
	return role === Role.Admin;
};

export const isClient = (role: Role) => {
	return role === Role.Client;
};

export const getDiscountTypeValue = (type: DiscountType): string => {
	switch (type) {
		case DiscountType.ONE_TIME:
			return "Unico";
		case DiscountType.MONTHLY:
			return "Mensal";
		case DiscountType.UNTIL_LIMIT:
			return "Até Limite";
		default:
			return "Unknown";
	}
};

export const formatMachineName = (
	machine: Machine,
	includeSerialNumber?: boolean
): string => {
	return `${machine.sageId} - ${machine.brand.name} ${machine.model.name} ${
		includeSerialNumber ? `(${machine.serialNumber})` : ""
	}`;
};

export const getBillingName = (billingType: BillingType) => {
	switch (billingType) {
		case BillingType.PER_CLIENT:
			return "Fatura única por cliente";
		case BillingType.PER_OFFICE:
			return "Faturação por escritório";
		case BillingType.PER_MACHINE:
			return "Faturação por máquina";
		case BillingType.PER_CONTRACT:
			return "Faturação por contrato";
		default:
			return "Desconhecido";
	}
};

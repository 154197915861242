import { CustomerFormType } from "../features/customerForm/CustomerValidationSchemas";
import { GetExtraArgsWithId } from "../utils/constants";
import { apiSlice, providesList } from "./apiSlice";
import { Office } from "./officeApi";
import { User } from "./userApi";

export interface Client {
	id: number;
	name: string;
	email: string;
	telephone: string;
	nif: number | string;
	active: boolean;
	users: User[];
	role: string;
	sageId?: number;
	offices?: Office[];
	// jobs: Jobs[]
	createdAt: string;
	updatedAt: string;
}

export const clientApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		createClient: build.mutation<Client, CustomerFormType>({
			query: (payload) => ({
				url: "clients/",
				method: "POST",
				body: payload,
			}),
			invalidatesTags: (_result, error, _arg) =>
				error
					? []
					: [
							{ type: "Client", id: "LIST" },
							{ type: "Machine", id: "LIST" },
					  ],
		}),
		updateClientById: build.mutation<Client, CustomerFormType>({
			query: (payload) => ({
				url: `/clients/${payload.id}`,
				method: "PUT",
				body: payload,
			}),
			invalidatesTags: (_result, error, arg) =>
				error
					? []
					: [
							{ type: "Client", id: arg.id },
							// { type: "Machine", id: arg.offices.flatMap }, // Invalidate only the a specific tag. but we can also add new machines so... invalidate every tag
							{ type: "Machine", id: "LIST" },
					  ],
		}),
		getAllClients: build.query<Client[], void>({
			query: () => "/clients",
			providesTags: (result) => providesList(result, "Client"),
		}),
		getClientById: build.query<Client, GetExtraArgsWithId>({
			query: (args) => {
				const params = new URLSearchParams();
				Object.entries(args).forEach(([key, value]) => {
					if (value && key !== "id") params.append(key, "true");
				});

				return {
					url: `clients/${args.id}?${params.toString()}`,
				};
			},
			providesTags: (result, _error, { id }) =>
				result ? [{ type: "Client", id }] : [],
		}),
		deleteClient: build.mutation<{ message: string }, number>({
			query: (id) => ({
				url: `clients/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: [
				{ type: "Client", id: "LIST" },
				{ type: "Machine", id: "LIST" },
			],
		}),
	}),
});

// export react hook
export const {
	useGetAllClientsQuery,
	useGetClientByIdQuery,
	useLazyGetClientByIdQuery,
	useCreateClientMutation,
	useUpdateClientByIdMutation,
	useDeleteClientMutation,
} = clientApi;

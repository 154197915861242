import { Tabs, TabList, TabPanels, Tab } from "@chakra-ui/react";
import { CustomerFormType } from "./CustomerValidationSchemas";
import { useFormContext } from "react-hook-form";
import MachineForm from "./MachineForm";

const CustomerStep2 = () => {
	const {
		getValues,
		formState: { errors },
	} = useFormContext<CustomerFormType>();

	return (
		<Tabs isFitted>
			<TabList flexWrap={"wrap"}>
				{getValues("offices")?.map((office, index) => {
					const isInvalid = errors?.offices?.[index];

					return (
						<Tab
							key={index}
							color={isInvalid ? "red" : undefined}
							borderBottom={
								isInvalid ? "1px solid red" : undefined
							}
							fontWeight={isInvalid ? "bold" : "normal"}
						>
							{office.name}
						</Tab>
					);
				})}
			</TabList>

			<TabPanels>
				{getValues("offices")?.map((field, index) => (
					<MachineForm
						key={index}
						officeIndex={index}
						officeId={field.id}
					/>
				))}
			</TabPanels>
		</Tabs>
	);
};

export default CustomerStep2;

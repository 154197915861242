import { Flex, Button, useToast } from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
	CopyCount,
	useCreateCopyCountMutation,
	useUpdateCopyCountMutation,
} from "../../api/copyCountsApi";
import { getErrorMessage } from "../../utils/helpers";
import { setEditing } from "./countsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const CountActions = () => {
	const dispatch = useAppDispatch();
	const toast = useToast();
	const {
		editingId,
		newCountData,
		isSubmitAvailable,
		machineContractDetails,
		updateCountData,
		lastCounts,
		isLoading,
	} = useAppSelector((state) => state.counts);

	const [createCopyCount] = useCreateCopyCountMutation();

	const [updateCopyCount] = useUpdateCopyCountMutation();

	const handleSubmit = async () => {
		const machineContractDetailsId = machineContractDetails?.id;
		if (!machineContractDetailsId) {
			return;
		}

		let promise: Promise<CopyCount[]>;
		if (editingId && updateCountData) {
			if (lastCounts.length === 0) {
				return;
			}

			const countToEdit = lastCounts.find(
				(count) => count.id === editingId
			);

			if (!countToEdit) return;

			const updateCopyCountPayload = {
				id: countToEdit.id,
				date: new Date(updateCountData.submitDate),
				blackCount: updateCountData.blackCount,
				colorCount: updateCountData.colorCount,
				machineContractDetailsId,
			};

			promise = updateCopyCount(updateCopyCountPayload).unwrap();
		} else {
			const newCopyCountPayload = {
				date: new Date(newCountData.submitDate),
				blackCount: newCountData.blackCount,
				colorCount: newCountData.colorCount,
				machineContractDetailsId,
			};

			promise = createCopyCount(newCopyCountPayload)
				.unwrap()
				.then((result) => [result]);
		}

		toast.promise(promise, {
			loading: {
				title: "A submeter contagem",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Contagem submetida",
				description:
					"A contagem das cópias foi submetida com sucesso.",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro na submissão de contagens",
					description: errorMessage,
				};
			},
		});
	};

	return (
		<Flex justifyContent={"right"}>
			{editingId && (
				<Button
					m={6}
					onClick={() => dispatch(setEditing(null))}
					isDisabled={isLoading}
					variant={"outline"}
				>
					Cancelar
				</Button>
			)}

			{(isSubmitAvailable || editingId) && (
				<Flex justifyContent={"flex-end"}>
					<Button
						m={6}
						onClick={handleSubmit}
						isDisabled={isLoading}
					>
						Submeter
					</Button>
				</Flex>
			)}
		</Flex>
	);
};

export default CountActions;

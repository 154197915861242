import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { Contract, MachineContractDetails } from "../../api/contractApi";
import { CopyCount, copyCountsApi } from "../../api/copyCountsApi";
import { Discount } from "../../api/discountsApi";
import { machineApi } from "../../api/machineApi";
import { previousMonthDate } from "../../utils/dateUtils";

const today = new Date();
// today.setDate(1);
console.debug(
	today.toLocaleDateString("pt-PT", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	})
);

interface CountData {
	blackCount: number;
	colorCount: number;
	blackCopiesTotalPrice: number;
	colorCopiesTotalPrice: number;
	total: number;
	submitDate: string;
}

interface CopyCountState {
	clientId: number | null;
	machineId: number | null;
	year: number | null;

	contract: Contract | null;
	machineContractDetails: MachineContractDetails | null;
	lastCounts: CopyCount[];
	discounts: Discount[];

	newCountData: CountData;
	updateCountData: CountData | null;
	countToUpdate: CopyCount | null;

	isSubmitAvailable: boolean;
	// isEditAvailable: boolean;
	editingId: number | null;
	contractNotFoundError: boolean;
	contractEnded: boolean;
	error: string | null;
	isAdmin: boolean;
	// For admin privilege submission
	newAdminCountData: {
		month: string;
		blackCount: number;
		colorCount: number;
		blackCopyUnitPrice: number;
		colorCopyUnitPrice: number;
		total: number;
		toggle: boolean;
	};
	isContractInactive: boolean;
	isLoading: boolean;
}

const newCountInitialState: CountData = {
	blackCount: 0,
	colorCount: 0,
	blackCopiesTotalPrice: 0,
	colorCopiesTotalPrice: 0,
	total: 0,
	submitDate: previousMonthDate(today).toISOString(),
};

const newAdminCountDataInitialState = {
	month: "January",
	blackCount: 0,
	colorCount: 0,
	blackCopyUnitPrice: 0,
	colorCopyUnitPrice: 0,
	total: 0,
	toggle: false,
};

const initialState: CopyCountState = {
	clientId: null,
	machineId: null,
	year: null,
	contract: null,
	machineContractDetails: null,
	lastCounts: [],
	newCountData: newCountInitialState,
	updateCountData: null,
	countToUpdate: null,
	isSubmitAvailable: false,
	// isEditAvailable: true,
	editingId: null,
	contractNotFoundError: true,
	error: null,
	discounts: [],
	contractEnded: false,
	isAdmin: false,
	newAdminCountData: newAdminCountDataInitialState,
	isContractInactive: false,
	isLoading: false,
};

const countsSlice = createSlice({
	name: "counts",
	initialState,
	reducers: {
		setClientId: (state, { payload }: PayloadAction<number>) => {
			state.clientId = payload;
			state.machineId = null;
			state.year = null;
		},
		setMachineId: (state, { payload }: PayloadAction<number>) => {
			state.machineId = payload;
			state.year = null;
		},
		setYear: (state, action: PayloadAction<number>) => {
			state.year = action.payload;
		},

		setBlackCount: (state, action: PayloadAction<number>) => {
			console.debug("setBlackCount: ", action.payload);
			if (isNaN(action.payload)) return;

			if (state.editingId) {
				state.countToUpdate =
					state.lastCounts.find(
						(count) => count.id === state.editingId
					) || null;

				if (state.updateCountData) {
					state.updateCountData.blackCount = action.payload;
				}
			} else {
				if (state.newCountData)
					state.newCountData.blackCount = action.payload;
			}
			calculateTotal(state);
		},
		setColorCount: (state, action: PayloadAction<number>) => {
			console.debug("setColorCount: ", action.payload);
			if (isNaN(action.payload)) return;

			if (state.editingId) {
				state.countToUpdate =
					state.lastCounts.find(
						(count) => count.id === state.editingId
					) || null;

				if (state.updateCountData) {
					state.updateCountData.colorCount = action.payload;
				}
			} else {
				if (state.newCountData)
					state.newCountData.colorCount = action.payload;
			}
			calculateTotal(state);
		},

		setEditing: (state, action: PayloadAction<number | null>) => {
			state.editingId = action.payload;

			if (!state.editingId) {
				state.updateCountData = null;
				state.countToUpdate = null;
				return;
			}

			state.countToUpdate =
				state.lastCounts.find(
					(count) => count.id === state.editingId
				) || null;

			if (!state.countToUpdate) {
				return;
			}

			state.updateCountData = {
				blackCount: state.countToUpdate.blackCount,
				colorCount: state.countToUpdate.colorCount,
				blackCopiesTotalPrice:
					state.countToUpdate.blackCopiesTotalPrice,
				colorCopiesTotalPrice:
					state.countToUpdate.colorCopiesTotalPrice,
				total: state.countToUpdate.monthlyFee,
				submitDate: state.countToUpdate.date.toString(),
			};

			calculateTotal(state);
		},
		setAdmin: (state, action: PayloadAction<boolean>) => {
			state.isAdmin = action.payload;
		},
		// resetCountsState: () => initialState,

		// Admin new count submit data
		setAdminCountMonth: (state, action: PayloadAction<string>) => {
			state.newAdminCountData.month = action.payload;
		},
		setAdminBlackCount: (state, action: PayloadAction<number>) => {
			state.newAdminCountData.blackCount = action.payload;
		},
		setAdminColorCount: (state, action: PayloadAction<number>) => {
			state.newAdminCountData.colorCount = action.payload;
		},
		setAdminBlackPrice: (state, action: PayloadAction<number>) => {
			state.newAdminCountData.blackCopyUnitPrice = action.payload;
		},
		setAdminColorPrice: (state, action: PayloadAction<number>) => {
			state.newAdminCountData.colorCopyUnitPrice = action.payload;
		},
		setAdminTotal: (state, action: PayloadAction<number>) => {
			state.newAdminCountData.total = action.payload;
		},
		setAdminNewCountToggle: (state, action: PayloadAction<boolean>) => {
			state.newAdminCountData.toggle = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			machineApi.endpoints.getMachineContractAndDetailsByMachineId
				.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			machineApi.endpoints.getMachineContractAndDetailsByMachineId
				.matchFulfilled,
			(state, { payload }) => {
				state.machineContractDetails = payload;
				state.contract = payload.contract || null;
				state.newCountData.total = Number(payload.monthlyFee);
				state.contractNotFoundError = false;
				state.discounts = payload.discounts || [];
				state.isContractInactive = payload.active === false;
				state.isLoading = false;
			}
		);
		builder.addMatcher(
			machineApi.endpoints.getMachineContractAndDetailsByMachineId
				.matchRejected,
			(state, { payload }) => {
				if (payload?.status === 404) {
					state.machineContractDetails = null;
					state.contract = null;
					state.contractNotFoundError = true;
					state.discounts = [];
					state.isContractInactive = false;
					state.isLoading = false;
				}
			}
		);

		// Get client last count (client side)
		builder.addMatcher(
			copyCountsApi.endpoints.getLastCountByContractIdAndMachineId
				.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.getLastCountByContractIdAndMachineId
				.matchFulfilled,
			(state, { payload }) => {
				state.lastCounts = [payload];
				state.isSubmitAvailable = isSubmitAvailable(state);
				// state.isEditAvailable = isEditAvailable(state);
				state.isLoading = false;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.getLastCountByContractIdAndMachineId
				.matchRejected,
			(state) => {
				state.lastCounts = [];
				state.isSubmitAvailable = isSubmitAvailable(state);
				// state.isEditAvailable = isEditAvailable(state);
				state.isLoading = false;
			}
		);

		// Get all counts (admin side)
		builder.addMatcher(
			copyCountsApi.endpoints.getAllCountsByContractIdAndMachineIdYearly
				.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.getAllCountsByContractIdAndMachineIdYearly
				.matchFulfilled,
			(state, { payload }) => {
				state.lastCounts = payload;
				state.isSubmitAvailable = isSubmitAvailable(state);
				// state.isEditAvailable = isEditAvailable(state);
				state.isLoading = false;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.getAllCountsByContractIdAndMachineIdYearly
				.matchRejected,
			(state, action) => {
				console.log(action.meta.condition);
				state.isSubmitAvailable = isSubmitAvailable(state);
				// state.isEditAvailable = isEditAvailable(state);
				state.isLoading = false;
			}
		);

		builder.addMatcher(
			copyCountsApi.endpoints.createCopyCount.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.createCopyCount.matchFulfilled,
			(state, { payload }) => {
				state.newCountData = newCountInitialState;
				state.updateCountData = null;
				state.countToUpdate = null;
				state.lastCounts =
					state.lastCounts.length > 0
						? [...state.lastCounts, payload]
						: [payload];
				state.isSubmitAvailable = false;
				state.contractNotFoundError = false;
				state.editingId = null;
				// state.isEditAvailable = isEditAvailable(state);
				state.isLoading = false;
			}
		);
		// builder.addMatcher(
		// 	copyCountsApi.endpoints.createCopyCountAdmin.matchFulfilled,
		// 	(state, { payload }) => {
		// 		state.newAdminCountData = newAdminCountDataInitialState;
		// 		state.updateCountData = null;
		// 		state.countToUpdate = null;
		// 		state.lastCounts =
		// 			state.lastCounts.length > 0
		// 				? [...state.lastCounts, payload]
		// 				: [payload];
		// 		state.isSubmitAvailable = false;
		// 		state.contractNotFoundError = false;
		// 		state.machineId = null;
		// 		state.editingId = null;
		// 		// state.isEditAvailable = isEditAvailable(state);
		// 		state.isLoading = false;
		// 	}
		// );

		builder.addMatcher(
			copyCountsApi.endpoints.updateCopyCount.matchPending,
			(state) => {
				state.isLoading = true;
			}
		);
		builder.addMatcher(
			copyCountsApi.endpoints.updateCopyCount.matchFulfilled,
			(state, { payload }) => {
				state.newCountData = newCountInitialState;
				state.updateCountData = null;
				state.countToUpdate = null;
				state.lastCounts = state.lastCounts.map((count) => {
					const updatedCount = payload.find(
						(item) => item.id === count.id
					);
					return updatedCount ? updatedCount : count;
				});
				state.isSubmitAvailable = false;
				state.contractNotFoundError = false;
				state.editingId = null;
			}
		);

		// Make sure that submit is only available when necessary
		builder.addMatcher(
			isAnyOf(setYear, setMachineId, setClientId, setEditing),
			(state) => {
				state.isSubmitAvailable = isSubmitAvailable(state);
			}
		);

		builder.addMatcher(
			isAnyOf(setYear, setMachineId, setClientId),
			(state) => {
				state.updateCountData = null;
				state.newCountData = newCountInitialState;
				state.editingId = null;
				state.newAdminCountData.toggle = false;
			}
		);

		builder.addMatcher(isAnyOf(setClientId), (state) => {
			state.contract = null;
			state.machineContractDetails = null;
		});

		// builder.addCase(setAuthState, (state, { payload }) => {
		// 	state.isEditAvailable = payload.role === Role.Admin ? true : false;
		// });
		// builder.addCase(refreshAuth, (state, { payload }) => {
		//   const decoded_data = decodeToken(payload.accessToken);
		//   console.log("Refresh auth decoded_data: ", decoded_data);
		//   if (decoded_data) {
		//     state.isEditAvailable = decoded_data.role === Role.Admin ? true : false;
		//   } else {
		//     state.isEditAvailable = false;
		//   }
		// });
		// hmm remove? use id from auth?
		// builder.addCase(refreshAuth, (state, { payload }) => {
		// 	const decoded_data = decodeToken(payload.accessToken);
		// 	if (decoded_data) {
		// 		state.clientId = decoded_data.clientId;
		// 	}
		// });
		// // Get the customer client id in case a customer logs in
		// builder.addMatcher(
		// 	authApi.endpoints.login.matchFulfilled,
		// 	(state, { payload }) => {
		// 		const decoded_data = decodeToken(payload.accessToken);
		// 		if (decoded_data) {
		// 			state.clientId = decoded_data.clientId;
		// 		}
		// 	}
		// );
	},
});

const calculateTotal = (state: CopyCountState) => {
	console.debug("Calculating total ...");
	state.newCountData.total = 0; // TODO: Temporary
	return;

	// if (!state.contract || !state.machineContractDetails) {
	// 	return;
	// }

	// let previousCopyCount;
	// if (state.editingId) {
	// 	const countToUpdate = JSON.parse(JSON.stringify(state.countToUpdate));
	// 	const updateCountData = JSON.parse(
	// 		JSON.stringify(state.updateCountData)
	// 	);

	// 	if (!countToUpdate || !updateCountData) return;

	// 	if (countToUpdate.discountsUsed) {
	// 		let { updatedDiscounts, updatedCopyCountDiscounts } =
	// 			revertDiscounts(state.discounts, countToUpdate.discountsUsed);

	// 		state.discounts = updatedDiscounts;
	// 		countToUpdate.discountsUsed = updatedCopyCountDiscounts;
	// 	}

	// 	let currentIndex = state.lastCounts.findIndex(
	// 		(copyCount) => copyCount.id === countToUpdate.id
	// 	);

	// 	// If the current copy count is not found or it is the first element, return null
	// 	if (currentIndex <= 0) {
	// 		previousCopyCount = null;
	// 	} else {
	// 		previousCopyCount = state.lastCounts[currentIndex];
	// 	}
	// } else {
	// 	previousCopyCount = state.lastCounts.at(-1);
	// 	console.debug("Date: ", state.newCountData.submitDate);
	// }

	// const blackCount =
	// 	state.updateCountData?.blackCount ?? state.newCountData.blackCount;

	// const colorCount =
	// 	state.updateCountData?.colorCount ?? state.newCountData.colorCount;

	// console.debug("blackCount:", blackCount, " / colorCount:", colorCount);

	// const newBlackCopies =
	// 	blackCount -
	// 	(previousCopyCount?.blackCount ??
	// 		state.machineContractDetails.initialCountsBlack);
	// const newColorCopies =
	// 	colorCount -
	// 	(previousCopyCount?.colorCount ??
	// 		state.machineContractDetails.initialCountsColor);

	// console.debug(
	// 	"initialCountsBlack",
	// 	state.machineContractDetails.initialCountsBlack
	// );
	// console.debug("previousCopyCount", previousCopyCount?.blackCount);

	// state.discounts = sortDiscountsByPriority(state.discounts, "desc");
	// console.debug("Discounts sorted.");

	// // Apply black copy discounts
	// const remainingBlackCopies = applyDiscounts(
	// 	state.discounts,
	// 	new Date(state.newCountData.submitDate),
	// 	newBlackCopies,
	// 	false
	// );

	// // Apply color copy discounts
	// const remainingColorCopies = applyDiscounts(
	// 	state.discounts,
	// 	new Date(state.newCountData.submitDate),
	// 	newColorCopies,
	// 	true
	// );

	// console.debug("remainingBlackCopies", remainingBlackCopies);
	// console.debug("remainingColorCopies", remainingColorCopies);

	// const blackCopyUnitPrice =
	// 	state.countToUpdate?.blackCopyUnitPrice ??
	// 	state.machineContractDetails.blackCopyUnitPrice;
	// const colorCopyUnitPrice =
	// 	state.countToUpdate?.colorCopyUnitPrice ??
	// 	state.machineContractDetails.colorCopyUnitPrice;

	// console.debug("blackCopyUnitPrice", blackCopyUnitPrice);
	// console.debug("colorCopyUnitPrice", colorCopyUnitPrice);

	// if (state.editingId) {
	// 	if (!state.updateCountData) return;
	// 	state.updateCountData.blackCopiesTotalPrice =
	// 		blackCopyUnitPrice * remainingBlackCopies;
	// 	state.updateCountData.colorCopiesTotalPrice =
	// 		colorCopyUnitPrice * remainingColorCopies;
	// 	state.updateCountData.total =
	// 		blackCopyUnitPrice * remainingBlackCopies +
	// 		colorCopyUnitPrice * remainingColorCopies +
	// 		state.machineContractDetails.monthlyFee;
	// } else {
	// 	state.newCountData.blackCopiesTotalPrice =
	// 		blackCopyUnitPrice * remainingBlackCopies;
	// 	state.newCountData.colorCopiesTotalPrice =
	// 		colorCopyUnitPrice * remainingColorCopies;

	// 	console.debug(
	// 		"blackCopiesTotalPrice",
	// 		state.newCountData.blackCopiesTotalPrice
	// 	);
	// 	console.debug(
	// 		"colorCopiesTotalPrice",
	// 		state.newCountData.colorCopiesTotalPrice
	// 	);
	// 	state.newCountData.total =
	// 		blackCopyUnitPrice * remainingBlackCopies +
	// 		colorCopyUnitPrice * remainingColorCopies +
	// 		state.machineContractDetails.monthlyFee;
	// }
};

// const calculateTotal = (state: CopyCountState) => {
// 	if (!state.contract || !state.machineContractDetails) {
// 		return;
// 	}

// 	let newBlackCopies = 0;
// 	let newColorCopies = 0;
// 	let blackCopyUnitPrice =
// 		state.countToUpdate?.blackCopyUnitPrice ??
// 		state.machineContractDetails.blackCopyUnitPrice;
// 	let colorCopyUnitPrice =
// 		state.countToUpdate?.colorCopyUnitPrice ??
// 		state.machineContractDetails.colorCopyUnitPrice;
// 	let monthlyFee =
// 		state.countToUpdate?.monthlyFee ??
// 		state.machineContractDetails.monthlyFee;

// 	let blackTotalPrice;
// 	let colorTotalPrice;

// 	if (state.editingId) {
// 		if (!state.updateCountData) return;
// 		console.debug("Calculating total in editing mode ...");
// 		console.debug("Count to update", state.countToUpdate);

// 		if (!state.countToUpdate) return;

// 		if (state.countToUpdate.discountsUsed) {
// 			let { updatedDiscounts, copyCountsDiscountsToDelete } =
// 				revertDiscounts(
// 					state.discounts,
// 					state.countToUpdate.discountsUsed
// 				);

// 			state.discounts = updatedDiscounts;
// 			state.countToUpdate.discountsUsed.filter(
// 				(discountUsed) =>
// 					!copyCountsDiscountsToDelete.includes(discountUsed)
// 			);
// 			console.log("updatedDiscounts", updatedDiscounts);
// 			console.log("discountsUsedTodelete", copyCountsDiscountsToDelete);
// 			console.log("discounts", state.discounts);
// 			console.log(
// 				"coutntote di discountsUsed",
// 				state.countToUpdate.discountsUsed
// 			);
// 		}

// 		// Apply black copy discounts
// 		let [
// 			remainingBlackCopies,
// 			blackCopiesDiscounted,
// 			blackDiscountUsageDetails,
// 		] = applyDiscounts(
// 			state.discounts,
// 			new Date(state.newCountData.submitDate),
// 			newBlackCopies,
// 			false
// 		);

// 		// Apply color copy discounts
// 		let [
// 			remainingColorCopies,
// 			colorCopiesDiscounted,
// 			colorDiscountUsageDetails,
// 		] = applyDiscounts(
// 			state.discounts,
// 			new Date(state.newCountData.submitDate),
// 			newColorCopies,
// 			true
// 		);

// 		console.log("remainingBlackCopies", remainingBlackCopies);
// 		console.log("blackDiscountUsageDetails", blackDiscountUsageDetails);
// 		console.log("remainingColorCopies", remainingColorCopies);
// 		console.log("colorDiscountUsageDetails", colorDiscountUsageDetails);

// 		if (!remainingBlackCopies || remainingBlackCopies < 0)
// 			remainingBlackCopies = 0;

// 		if (!remainingColorCopies || remainingColorCopies < 0)
// 			remainingColorCopies = 0;

// 		blackTotalPrice = blackCopyUnitPrice * remainingBlackCopies;
// 		colorTotalPrice = colorCopyUnitPrice * remainingColorCopies;

// 		state.countToUpdate.blackCopiesDiscounted = blackCopiesDiscounted;
// 		state.countToUpdate.colorCopiesDiscounted = colorCopiesDiscounted;
// 		// update the state

// 		state.updateCountData.blackCopiesTotalPrice = blackTotalPrice;
// 		state.updateCountData.colorCopiesTotalPrice = colorTotalPrice;
// 		state.updateCountData.total =
// 			blackCopyUnitPrice * remainingBlackCopies +
// 			colorCopyUnitPrice * remainingColorCopies +
// 			monthlyFee;
// 	} else {
// 		console.debug("Calculating total in normal mode ...");
// 		const lastCopyCount = state.lastCounts.at(-1);

// 		newBlackCopies =
// 			state.newCountData.blackCount -
// 			(lastCopyCount?.blackCount ??
// 				state.machineContractDetails.initialCountsBlack);
// 		newColorCopies =
// 			state.newCountData.colorCount -
// 			(lastCopyCount?.colorCount ??
// 				state.machineContractDetails.initialCountsColor);

// 		// Apply black copy discounts
// 		let [
// 			remainingBlackCopies,
// 			blackCopiesDiscounted,
// 			blackDiscountUsageDetails,
// 		] = applyDiscounts(
// 			state.discounts,
// 			new Date(state.newCountData.submitDate),
// 			newBlackCopies,
// 			false
// 		);

// 		// Apply color copy discounts
// 		let [
// 			remainingColorCopies,
// 			colorCopiesDiscounted,
// 			colorDiscountUsageDetails,
// 		] = applyDiscounts(
// 			state.discounts,
// 			new Date(state.newCountData.submitDate),
// 			newColorCopies,
// 			true
// 		);

// 		blackTotalPrice = blackCopyUnitPrice * remainingBlackCopies;
// 		colorTotalPrice = colorCopyUnitPrice * remainingColorCopies;

// 		state.newCountData.blackCopiesTotalPrice = blackTotalPrice;
// 		state.newCountData.colorCopiesTotalPrice = colorTotalPrice;
// 		state.newCountData.total =
// 			blackCopyUnitPrice * remainingBlackCopies +
// 			colorCopyUnitPrice * remainingColorCopies +
// 			monthlyFee;
// 	}
// };

// const calculateTotal = (
// 	state: CopyCountState,
// 	countToEdit: CopyCount | null
// ) => {
// 	if (!state.contract || !state.machineContractDetails) {
// 		return;
// 	}

// 	let newBlackCopies = 0;
// 	let newColorCopies = 0;
// 	let blackCopiesTotalDiscountAvailable =
// 		state.updateCountData?.blackCopiesTotalDiscountAvailable ??
// 		state.newCountData.blackCopiesTotalDiscountAvailable;
// 	let colorCopiesTotalDiscountAvailable =
// 		state.updateCountData?.colorCopiesTotalDiscountAvailable ??
// 		state.newCountData.colorCopiesTotalDiscountAvailable;
// 	let blackCopyUnitPrice =
// 		countToEdit?.blackCopyUnitPrice ??
// 		state.machineContractDetails.blackCopyUnitPrice;
// 	let colorCopyUnitPrice =
// 		countToEdit?.colorCopyUnitPrice ??
// 		state.machineContractDetails.colorCopyUnitPrice;
// 	let blackTotalPrice;
// 	let colorTotalPrice;
// 	let blackCopiesDiff;
// 	let colorCopiesDiff;

// 	if (state.editingId) {
// 		if (!state.updateCountData) return;
// 		console.debug("Calculating total in editing mode ...");

// 		const countToEdit = state.lastCounts.find(
// 			(count) => count.id === state.editingId
// 		);

// 		if (!countToEdit) return;

// 		blackCopiesDiff =
// 			state.updateCountData.blackCount - countToEdit?.blackCount;
// 		colorCopiesDiff =
// 			state.updateCountData.colorCount - countToEdit?.colorCount;

// 		console.debug("countToEdit", countToEdit.blackCount);
// 		console.debug("blackCopiesDiff", blackCopiesDiff);

// 	} else {
// 		console.debug("Calculating total in normal mode ...");
// 		if (state.lastCounts.length > 0) {
// 			const lastCount = state.lastCounts.at(-1);

// 			newBlackCopies =
// 				state.newCountData.blackCount - (lastCount?.blackCount ?? 0);
// 			newColorCopies =
// 				state.newCountData.colorCount - (lastCount?.colorCount ?? 0);
// 		} else {
// 			newBlackCopies =
// 				state.newCountData.blackCount -
// 				state.machineContractDetails.initialCountsBlack;
// 			newColorCopies =
// 				state.newCountData.colorCount -
// 				state.machineContractDetails.initialCountsColor;
// 		}
// 	}

// 	blackTotalPrice =
// 		Math.max(newBlackCopies - blackCopiesTotalDiscountAvailable, 0) *
// 		blackCopyUnitPrice;

// 	colorTotalPrice =
// 		Math.max(newColorCopies - colorCopiesTotalDiscountAvailable, 0) *
// 		colorCopyUnitPrice;

// 	state.newCountData.blackCopiesTotalPrice = blackTotalPrice;
// 	state.newCountData.colorCopiesTotalPrice = colorTotalPrice;
// 	state.newCountData.total =
// 		blackTotalPrice +
// 		colorTotalPrice +
// 		state.machineContractDetails.monthlyFee;
// };

// Helper function to update submit availability

const isSubmitAvailable = (state: CopyCountState) => {
	// Admin Validations
	if (state.isAdmin && state.year !== today.getFullYear()) {
		console.debug("Wrong year selected for submit");
		return false;
	}
	// End Admin Validations

	const isBeforeMidMonth = today.getDate() <= 15;
	if (!isBeforeMidMonth) {
		console.debug(`O dia de hoje não é anterior a dia 15`);
		return false;
	}

	if (!state.contract) {
		console.debug("Sem contrato");
		return false;
	}

	if (!state.contract.active) return false;

	const contractStartDate = new Date(state.contract.startDate);
	const contractEndDate = new Date(state.contract.endDate);

	const isWithinContractPeriod =
		today.getTime() > contractStartDate.getTime() &&
		today.getTime() < contractEndDate.getTime();

	if (!isWithinContractPeriod) {
		state.contractEnded = true;
		console.debug("Not within contract period");
		return false;
	}

	if (state.lastCounts.length > 0) {
		const lastCountDate: Date = new Date(
			state.lastCounts[state.lastCounts.length - 1].date
		);
		const submitDate: Date = new Date(state.newCountData.submitDate);

		const isNotCurrentMonthAndYear =
			submitDate.getMonth() !== lastCountDate.getMonth() ||
			submitDate.getFullYear() !== lastCountDate.getFullYear();

		const isSubmitDateAfterLastCount = submitDate > lastCountDate;

		if (!isNotCurrentMonthAndYear) {
			console.debug("isNotCurrentMonthAndYear");
			return false;
		}

		if (!isSubmitDateAfterLastCount) {
			console.debug("isSubmitDateAfterLastCount");
			return false;
		}
	}

	state.contractEnded = false;
	return true;
};

// const isEditAvailable = (state: CopyCountState) => {
// 	const isBeforeMidMonth = today.getDate() <= 15;
// 	if (!isBeforeMidMonth) {
// 		return false;
// 	}

// 	if (state.contract) {
// 		const contractStartDate = new Date(state.contract.startDate);
// 		const contractEndDate = new Date(state.contract.endDate);

// 		const isWithinContractPeriod =
// 			today.getTime() > contractStartDate.getTime() &&
// 			today.getTime() < contractEndDate.getTime();

// 		if (!isWithinContractPeriod) {
// 			return false;
// 		}
// 	}

// 	if (state.lastCount) {
// 		const lastCountDate: Date = new Date(state.lastCount.date);
// 		const submitDate: Date = new Date(state.submitDate);

// 		const isNotCurrentMonthAndYear =
// 			submitDate.getMonth() === lastCountDate.getMonth() ||
// 			submitDate.getFullYear() === lastCountDate.getFullYear();

// 		if (isNotCurrentMonthAndYear) {
// 			return true;
// 		}
// 	}

// 	return false;
// };

export const {
	setBlackCount,
	setColorCount,
	setMachineId,
	setEditing,
	setClientId,
	setYear,
	setAdmin,
	setAdminBlackCount,
	setAdminBlackPrice,
	setAdminColorCount,
	setAdminColorPrice,
	setAdminCountMonth,
	setAdminTotal,
	setAdminNewCountToggle,
} = countsSlice.actions;

export default countsSlice.reducer;

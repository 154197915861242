import { Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import LandingPage from "./pages/Landing.page";
import LoginPage from "./pages/Login.page";
import BillingsPage from "./pages/admin/Billings.page";
import ClientsPage from "./pages/admin/Clients.page";
import DashboardPage from "./pages/admin/Dashboard.page";
import CustomerHomePage from "./pages/CustomerHome.page";
import PageCountsPage from "./pages/Counts.page";
import customTheme from "./app/theme";
import RequireAuth from "./utils/RequireAuth";
import { Role } from "./utils/constants";
import ContractPage from "./pages/admin/Contract.page";
import ContractFormPage from "./pages/admin/ContractForm.page";
import ContractDetailsPage from "./pages/admin/ContractDetails.Page";
import RegisterPage from "./pages/Register.page";
import { isAdmin } from "./utils/helpers";
import { setAdmin } from "./features/counts/countsSlice";
import CustomerDetailsPage from "./pages/admin/CustomerDetails.page";
import CustomerFormPage from "./pages/admin/CustomerForm.page";
import { useAppDispatch, useAppSelector } from "./app/hooks";

export const LayoutWithNavbar = styled.div`
	margin-top: 135px;
`;

function App() {
	const dispatch = useAppDispatch();
	const { isAuthenticated, role, clientId } = useAppSelector((state) => state.auth);

	useEffect(() => {
		dispatch(setAdmin(isAdmin(role)))
	}, [clientId, isAuthenticated, role, dispatch]);

	return (
		<ChakraProvider
			theme={customTheme}
			toastOptions={{ defaultOptions: { position: "top" } }}
		>
			<Routes>
				{/* public routes */}
				<Route path="/" element={<LandingPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/register/:token" element={<RegisterPage />} />

				{/* protected routes */}
				<Route
					path="/client"
					element={<RequireAuth allowedRoles={[Role.Client]} />}
				>
					<Route path="" element={<CustomerHomePage />} />
				</Route>
				<Route
					path="/admin"
					element={<RequireAuth allowedRoles={[Role.Admin]} />}
				>
					<Route path="" element={<DashboardPage />} />
					{/* <Route path="/jobs" element={<JobsPage />} /> */}
					<Route path="clients" element={<ClientsPage />} />
					<Route path="create/client" element={<CustomerFormPage />} />
					<Route path="edit/client/:id" element={<CustomerFormPage />} />
					<Route
						path="clients/:id/details"
						element={<CustomerDetailsPage />}
					/>

					<Route path="billing" element={<BillingsPage />} />


					<Route path="contracts" element={<ContractPage />} />
					<Route
						path="contracts/:id/details"
						element={<ContractDetailsPage />}
					/>
					<Route
						path="create/contracts"
						element={<ContractFormPage />}
					/>
					<Route
						path="edit/contract/:id"
						element={<ContractFormPage />}
					/>
				</Route>

				{/* commonn routes */}
				<Route
					path="/client"
					element={
						<RequireAuth
							allowedRoles={[Role.Client, Role.Admin]}
						/>
					}
				>
					<Route path="counts" element={<PageCountsPage />} />
				</Route>

				{/* This route will render if no other route matches */}
				<Route
					path="*"
					element={<p>There&apos;s nothing here: 404!</p>}
				/>
			</Routes>
		</ChakraProvider>
	);
}

export default App;

import {
	Card,
	CardBody,
	CardFooter,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	Radio,
	RadioGroup,
	SimpleGrid,
	Stack,
	useToast,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CustomerFormType } from "./CustomerValidationSchemas";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useLazyIsMachineDeletableQuery } from "../../api/machineApi";
import Loading from "../../components/Loading";

const CustomerStep1 = () => {
	const toast = useToast();
	const {
		control,
		register,
		formState: { errors },
		getValues,
	} = useFormContext<CustomerFormType>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "offices",
		keyName: "_id",
	});

	const [isMachineDeletable, { isLoading, isFetching }] =
		useLazyIsMachineDeletableQuery();

	const handleOfficeRemoval = async (index: number) => {
		try {
			const machines = getValues(`offices.${index}.machines`) || [];

			for (const machine of machines) {
				console.log(machine.id)
				if (machine.id === 0) continue;
				const response = await isMachineDeletable(machine.id).unwrap();
				if (!response.isDeletable) {
					toast({
						status: "warning",
						title: `Escritório não pode ser apagado`,
						description: response.message,
						isClosable: true,
					});
					return;
				}
			}
			remove(index);
		} catch (err) {
			return;
		}
	};

	if (isLoading || isFetching) {
		return <Loading />;
	}

	return (
		<SimpleGrid spacing={4} templateColumns="repeat(3, 1fr)">
			{fields.map((field, index) => (
				<Card key={field._id}>
					<CardBody>
						<Flex flexDirection={"column"} gap={3}>
							<FormControl
								isInvalid={
									!!errors?.offices?.[index]?.name?.message
								}
								isRequired
							>
								<FormLabel>Nome</FormLabel>
								<Input
									type="text"
									{...register(
										`offices.${index}.name` as const
									)}
								/>
								<FormErrorMessage>
									{errors.offices?.[index]?.name?.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									!!errors?.offices?.[index]?.email?.message
								}
								isRequired
							>
								<FormLabel>Email</FormLabel>
								<Input
									type="text"
									{...register(
										`offices.${index}.email` as const
									)}
								/>
								<FormErrorMessage>
									{errors.offices?.[index]?.email?.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									!!errors?.offices?.[index]?.telephone
										?.message
								}
								isRequired
							>
								<FormLabel>Telefone</FormLabel>
								<Input
									type="text"
									{...register(
										`offices.${index}.telephone` as const
									)}
								/>
								<FormErrorMessage>
									{
										errors.offices?.[index]?.telephone
											?.message
									}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									!!errors?.offices?.[index]?.address
										?.message
								}
								isRequired
							>
								<FormLabel>Morada</FormLabel>
								<Input
									type="text"
									{...register(
										`offices.${index}.address` as const
									)}
								/>
								<FormErrorMessage>
									{errors.offices?.[index]?.address?.message}
								</FormErrorMessage>
							</FormControl>
							<Flex gap={2} alignItems={"flex-end"}>
								<FormControl
									isInvalid={
										!!errors?.offices?.[index]?.postalCode
											?.message
									}
									isRequired
									flexShrink={2}
								>
									<FormLabel>Código Postal</FormLabel>
									<Input
										type="text"
										{...register(
											`offices.${index}.postalCode` as const
										)}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[index]?.postalCode
												?.message
										}
									</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={
										!!errors?.offices?.[index]?.location
											?.message
									}
									isRequired
									flexGrow={2}
								>
									<FormLabel>Localidade</FormLabel>
									<Input
										type="text"
										{...register(
											`offices.${index}.location` as const
										)}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[index]?.location
												?.message
										}
									</FormErrorMessage>
								</FormControl>
							</Flex>
							<Flex gap={3}>
								<FormControl
									isInvalid={
										!!errors?.offices?.[index]?.latitude
											?.message
									}
								>
									<FormLabel>Latitute</FormLabel>
									<Input
										type="text"
										{...register(
											`offices.${index}.latitude` as const
										)}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[index]?.latitude
												?.message
										}
									</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={
										!!errors?.offices?.[index]?.longitude
											?.message
									}
								>
									<FormLabel>Longitude</FormLabel>
									<Input
										type="text"
										{...register(
											`offices.${index}.longitude` as const
										)}
									/>
									<FormErrorMessage>
										{
											errors.offices?.[index]?.longitude
												?.message
										}
									</FormErrorMessage>
								</FormControl>
							</Flex>
							<FormControl
								isInvalid={
									!!errors.offices?.[index]?.mainOffice
										?.message
								}
							>
								<FormLabel>Sede</FormLabel>
								<Controller
									control={control}
									name={`offices.${index}.mainOffice`}
									defaultValue="true"
									render={({
										field: { onChange, value, ref },
									}) => (
										<RadioGroup
											onChange={onChange}
											value={
												value
													? value.toString()
													: "false"
											}
											ref={ref}
										>
											<Stack spacing={5} direction="row">
												<Radio
													colorScheme="green"
													value="true"
												>
													Sim
												</Radio>
												<Radio
													colorScheme="red"
													value="false"
												>
													Não
												</Radio>
											</Stack>
										</RadioGroup>
									)}
								/>
								<FormErrorMessage>
									{
										errors.offices?.[index]?.mainOffice
											?.message
									}
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</CardBody>
					<CardFooter>
						{fields.length > 0 && (
							<IconButton
								aria-label="Remove object"
								icon={<MinusIcon />}
								variant="outline"
								onClick={() => handleOfficeRemoval(index)}
							/>
						)}
					</CardFooter>
				</Card>
			))}
			<Flex alignItems={"center"}>
				<IconButton
					aria-label="Add object"
					icon={<AddIcon />}
					onClick={() =>
						append({
							id: 0,
							name: "",
							email: "",
							address: "",
							postalCode: "",
							location: "",
							telephone: "",
							latitude: "",
							longitude: "",
							mainOffice: "false",
						})
					}
				/>
			</Flex>
		</SimpleGrid>
	);
};

export default CustomerStep1;

import { Flex, GridItem, Heading, Select } from "@chakra-ui/react";
import UserLoggedLayout from "../../layouts/UserLoggedLayout";

const BillingsPage = () => {
  const handleClientChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // const machineId = parseInt(event.target.value, 10);
    // if (machineId) {
    //   dispatch(resetCountsState());
    //   dispatch(setSelectedMachineId(machineId));
    //   getContractByMachineId(machineId);
    // }
  };

  const handleOfficeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // const machineId = parseInt(event.target.value, 10);
    // if (machineId) {
    //   dispatch(resetCountsState());
    //   dispatch(setSelectedMachineId(machineId));
    //   getContractByMachineId(machineId);
    // }
  };

  return (
    <UserLoggedLayout>
      <GridItem colStart={3} colEnd={12}>
        <Heading my={4}>Faturação</Heading>

        <Flex>
          <Select
            placeholder="Selecione o cliente"
            mt={8}
            onChange={handleClientChange}
          ></Select>

          <Select
            placeholder="Selecione o escritorio"
            mt={8}
            onChange={handleOfficeChange}
          ></Select>
        </Flex>
      </GridItem>
    </UserLoggedLayout>
  );
};

export default BillingsPage;

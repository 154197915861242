import {
	Badge,
	Box,
	Card,
	CardHeader,
	Flex,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import { Machine } from "../../api/machineApi";
import { formatMachineName } from "../../utils/helpers";
import { useGetMissingCountsByClientIdQuery } from "../../api/copyCountsApi";
import { useAppSelector } from "../../app/hooks";
import { skipToken } from "@reduxjs/toolkit/query";

interface MachineCardProps {
	machine: Machine;
}

const MachineCard = ({ machine }: MachineCardProps) => {
	const { clientId } = useAppSelector((state) => state.auth);

	const { data: missingCounts } = useGetMissingCountsByClientIdQuery(
		clientId
			? {
					clientId,
			  }
			: skipToken
	);

	return (
		<Card>
			<CardHeader>
				<Flex
					alignItems={"center"}
					justifyContent={"space-between"}
					gap={5}
				>
					<Box>
						<Heading size="md">
							{formatMachineName(machine)}
						</Heading>
						<Stack direction={"row"}>
							<Text fontSize="xs" color="gray.500">
								{machine.serialNumber}
							</Text>
							<Text fontSize="xs" color="gray.500">
								{machine.type.name}
							</Text>
						</Stack>
					</Box>
					{/* {machine.active ? (
						<CheckCircleIcon color="green" />
					) : (
						<SmallCloseIcon color="red" />
					)} */}
					{missingCounts && missingCounts.machines.some((m) => m.id === machine.id) && (
						<Badge colorScheme="yellow">Contagem</Badge>
					)}
				</Flex>
			</CardHeader>
			{/* <CardBody>
				<SimpleGrid spacing={4} templateColumns="repeat(4, 1fr)">
					<Flex flexDirection={"column"} alignItems={"center"}>
						<Text>- %</Text>
						<Box h={"150px"} w={"50px"} background={"black"}></Box>
						<Text>Preto</Text>
					</Flex>
					<Flex flexDirection={"column"} alignItems={"center"}>
						<Text>- %</Text>
						<Box h={"150px"} w={"50px"} background={"cyan"}></Box>
						<Text>Cyan</Text>
					</Flex>
					<Flex flexDirection={"column"} alignItems={"center"}>
						<Text>- %</Text>
						<Box h={"150px"} w={"50px"} background={"magenta"}></Box>
						<Text>Magenta</Text>
					</Flex>
					<Flex flexDirection={"column"} alignItems={"center"}>
						<Text>- %</Text>
						<Box h={"150px"} w={"50px"} background={"yellow"}></Box>
						<Text>Yellow</Text>
					</Flex>
				</SimpleGrid>
			</CardBody> */}
		</Card>
	);
};

export default MachineCard;

import { createSlice } from "@reduxjs/toolkit";
import { Client } from "../api/clientApi";

const initialState: Client = {
  id: 0,
  name: '',
  email: '',
  telephone: '',
  nif: 0,
  active: false,
  role: "Client",
  users: [],
  offices: [],
  createdAt: '',
  updatedAt: ''
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
});

//export const {} = clientSlice.actions;

export default clientSlice.reducer;

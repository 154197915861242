import {
	Alert,
	AlertIcon,
	GridItem,
	Heading,
	Stack,
	Text,
} from "@chakra-ui/react";
import ContractCard from "../features/counts/ContractCard";
import CountsTable from "../features/counts/CountsTable";
import UserLoggedGridLayout from "../layouts/UserLoggedGridLayout";
import { isAdmin } from "../utils/helpers";
import { useAppSelector } from "../app/hooks";
import CountsSelectors from "../features/counts/CountsSelectors";
import CountsBilling from "../features/counts/CountsBilling";
import CountActions from "../features/counts/CountActions";
import { formatDate } from "../utils/dateUtils";
import Loading from "../components/Loading";

const CountsPage = () => {
	const { role } = useAppSelector((state) => state.auth);
	const {
		machineId,
		contract,
		contractNotFoundError,
		isContractInactive,
		isLoading,
	} = useAppSelector((state) => state.counts);

	if (isLoading) <Loading />;

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Heading>Inserir Contagens</Heading>
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 2 }}>
				<CountsSelectors />
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 5 }}>
				{isAdmin(role) && <ContractCard />}
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 3 }}>
				<CountsBilling />
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				{/* Alerts  */}
				<Stack spacing={3} mt={5}>
					{!machineId && (
						<Alert status="info">
							<AlertIcon />
							Selecione uma máquina
						</Alert>
					)}

					{machineId && contractNotFoundError && (
						<Alert status="warning">
							<AlertIcon />
							Contrato não existe
						</Alert>
					)}

					{isContractInactive && (
						<Alert status="info">
							<AlertIcon />
							<Text>
								Contrato cessado em:{" "}
								{contract && formatDate(contract.ceaseDate)}
							</Text>
						</Alert>
					)}
				</Stack>

				<CountsTable />

				<CountActions />
				{/*

				

				{!contractNotFoundError && <CountsTable />}

				 */}
				{/* {isAdmin(role) &&
					selectedMachineId &&
					newAdminCountData.toggle && (
						<Flex justifyContent={"flex-end"}>
							<Button
								m={6}
								onClick={handleSubmitAsAdmin}
								isDisabled={isLoading}
								bg={"blue"}
							>
								Submeter como administrador
							</Button>
						</Flex>
					)} */}
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default CountsPage;

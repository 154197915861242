import Select from "react-select";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ContractFormType } from "./ContractValidationSchemas";
import {
	Office,
	useGetOfficesAndMachinesByClientIdQuery,
} from "../../api/officeApi";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
	SimpleGrid,
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	CardFooter,
	IconButton,
	CheckboxGroup,
	Checkbox,
	Stack,
	NumberInput,
	NumberInputField,
} from "@chakra-ui/react";
import { discountTypes } from "./ContractFormConfig";
import { DiscountType } from "../../api/discountsApi";
import { formatMachineName } from "../../utils/helpers";

interface ContractStep2Props {
	clientId: number;
}

const ContractStep2 = ({ clientId }: ContractStep2Props) => {
	const { data: officesWithMachines } =
		useGetOfficesAndMachinesByClientIdQuery(clientId);

	const {
		control,
		formState: { errors },
		register,
		setValue,
		getValues,
		watch,
	} = useFormContext<ContractFormType>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "discounts",
		keyName: "_id",
	});

	const getMachineCheckboxOptions = () => {
		return (
			officesWithMachines
				?.reduce(
					(
						acc: {
							id: string;
							label: string;
						}[],
						o: Office
					) => {
						const machineIds = (o.machines || [])
							.map((m) => ({
								id: m.id.toString(),
								label: formatMachineName(m),
							}))
							.filter((machine) =>
								getValues("machinesContractDetails")?.some(
									(d) => d.machineId === machine.id
								)
							);

						return [...acc, ...machineIds];
					},
					[]
				)
				.map((machine) => (
					<Checkbox key={machine.id} value={machine.id}>
						{machine.label}
					</Checkbox>
				)) || []
		);
	};

	return (
		<SimpleGrid spacing={4} templateColumns="repeat(4, 1fr)">
			{fields.map((field, index) => {
				const discountType = watch(`discounts.${index}.discountType`);

				return (
					<Card key={field._id}>
						<CardBody>
							<Flex flexDirection={"column"} gap={3}>
								<FormControl
									isInvalid={
										!!errors?.discounts?.[index]
											?.discountType?.message
									}
									isRequired
								>
									<FormLabel>Tipo de contrato</FormLabel>
									<Select
										{...register(
											`discounts.${index}.discountType` as const
										)}
										options={discountTypes}
										value={discountTypes.find(
											(type) =>
												type.value ===
												getValues(
													`discounts.${index}.discountType`
												)
										)}
										onChange={(option) =>
											setValue(
												`discounts.${index}.discountType`,
												option!.value as DiscountType,
												{
													shouldValidate: true,
												}
											)
										}
										placeholder={"Tipo de contrato"}
									/>
									<FormErrorMessage>
										{
											errors.discounts?.[index]
												?.discountType?.message
										}
									</FormErrorMessage>
								</FormControl>
								{discountType === DiscountType.ONE_TIME && (
									<Flex gap={4} w={"100%"} flexWrap={"wrap"}>
										<FormControl
											isInvalid={
												!!errors?.discounts?.[index]
													?.startDate?.message
											}
											isRequired
										>
											<FormLabel>
												Data de início
											</FormLabel>
											<Input
												type="date"
												placeholder="dd/mm/aaaa"
												{...register(
													`discounts.${index}.startDate`
												)}
											/>
											<FormErrorMessage>
												{
													errors?.discounts?.[index]
														?.startDate?.message
												}
											</FormErrorMessage>
										</FormControl>

										<FormControl
											isInvalid={
												!!errors?.discounts?.[index]
													?.endDate?.message
											}
											isRequired
										>
											<FormLabel>Data de fim</FormLabel>
											<Input
												type="date"
												{...register(
													`discounts.${index}.endDate`
												)}
											/>
											<FormErrorMessage>
												{
													errors?.discounts?.[index]
														?.endDate?.message
												}
											</FormErrorMessage>
										</FormControl>
									</Flex>
								)}
								<FormControl
									isInvalid={
										!!errors.discounts?.[index]?.machineIds
											?.message
									}
									isRequired
								>
									<FormLabel>Aplicar a máquinas</FormLabel>
									<Controller
										name={`discounts.${index}.machineIds`}
										control={control}
										render={({
											field: { ref, ...field },
										}) => (
											<Stack direction={"column"}>
												<CheckboxGroup {...field}>
													{getMachineCheckboxOptions()}
												</CheckboxGroup>
											</Stack>
										)}
									/>
									<FormErrorMessage>
										{
											errors.discounts?.[index]
												?.machineIds?.message
										}
									</FormErrorMessage>
								</FormControl>
								<Flex gap={2} alignItems={"flex-end"}>
									<FormControl
										isInvalid={
											!!errors?.discounts?.[index]
												?.blackCopyDiscountAmount
												?.message
										}
										isRequired
									>
										<FormLabel>Desconto Preto</FormLabel>
										<NumberInput>
											<NumberInputField
												{...register(
													`discounts.${index}.blackCopyDiscountAmount` as const
												)}
											/>
										</NumberInput>
										<FormErrorMessage>
											{
												errors?.discounts?.[index]
													?.blackCopyDiscountAmount
													?.message
											}
										</FormErrorMessage>
									</FormControl>
									{/* <FormControl
										isInvalid={
											!!errors?.discounts?.[index]
												?.usedBlackCopies?.message
										}
										isRequired
									>
										<FormLabel>
											Desconto Preto Utilizado
										</FormLabel>
										<Input
											type="number"
											{...register(
												`discounts.${index}.usedBlackCopies` as const
											)}
										/>
										<FormErrorMessage>
											{
												errors?.discounts?.[index]
													?.usedBlackCopies?.message
											}
										</FormErrorMessage>
									</FormControl> */}
								</Flex>
								<Flex gap={2} alignItems={"flex-end"}>
									<FormControl
										isInvalid={
											!!errors?.discounts?.[index]
												?.colorCopyDiscountAmount
												?.message
										}
										isRequired
									>
										<FormLabel>Desconto Cor</FormLabel>
										<NumberInput>
											<NumberInputField
												{...register(
													`discounts.${index}.colorCopyDiscountAmount` as const
												)}
											/>
										</NumberInput>
										<FormErrorMessage>
											{
												errors?.discounts?.[index]
													?.colorCopyDiscountAmount
													?.message
											}
										</FormErrorMessage>
									</FormControl>
									{/* <FormControl
										isInvalid={
											!!errors?.discounts?.[index]
												?.usedColorCopies?.message
										}
										isRequired
									>
										<FormLabel>
											Desconto Cor Utilizado
										</FormLabel>
										<Input
											type="number"
											{...register(
												`discounts.${index}.usedColorCopies` as const
											)}
										/>
										<FormErrorMessage>
											{
												errors?.discounts?.[index]
													?.usedColorCopies?.message
											}
										</FormErrorMessage>
									</FormControl> */}
								</Flex>
							</Flex>
						</CardBody>
						<CardFooter>
							{fields.length > 0 && (
								<IconButton
									aria-label="Remove object"
									icon={<MinusIcon />}
									variant="outline"
									onClick={() => remove(index)}
								/>
							)}
						</CardFooter>
					</Card>
				);
			})}
			<Flex alignItems={"center"}>
				<IconButton
					aria-label="Add object"
					icon={<AddIcon />}
					onClick={() =>
						append({
							id: 0,
							discountType: "",
							startDate: "",
							endDate: "",
							blackCopyDiscountAmount: "",
							colorCopyDiscountAmount: "",
							// usedBlackCopies: 0,
							// usedColorCopies: 0,
							machineIds: [],
						})
					}
				/>
			</Flex>
		</SimpleGrid>
	);
};

export default ContractStep2;

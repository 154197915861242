import {
	Card,
	CardHeader,
	Heading,
	CardBody,
	Stack,
	StackDivider,
	Box,
	Text,
	Container,
	Flex,
	Divider,
} from "@chakra-ui/react";
import { Discount } from "../../api/discountsApi";
import { formatDateMonthAndYear } from "../../utils/dateUtils";
import { getDiscountTypeValue } from "../../utils/helpers";
import { useAppSelector } from "../../app/hooks";

const ContractCard = () => {
	const { contract, machineContractDetails, discounts } = useAppSelector(
		(state) => state.counts
	);

	return (
		<Container>
			<Card>
				<CardHeader>
					<Heading size="md">Contrato</Heading>
				</CardHeader>

				<CardBody>
					<Stack
						direction={"row"}
						spacing={8}
						divider={<StackDivider />}
					>
						<Stack divider={<StackDivider flex="1 1 auto" />} spacing="4" flex="1 1 auto">
							<Box>
								<Flex direction="row" align="flex-start">
									<Heading
										size="sm"
										textTransform="uppercase"
										mr={1}
									>
										Datas
									</Heading>
									<Text fontSize="sm" color="gray.600">
										(início e fim)
									</Text>
								</Flex>

								{contract ? (
									<Text pt="2" fontSize="sm">
										{new Date(
											contract.startDate
										).toLocaleDateString("pt-PT", {
											day: "numeric",
											month: "numeric",
											year: "numeric",
										})}{" "}
										-{" "}
										{new Date(
											contract.endDate
										).toLocaleDateString("pt-PT", {
											day: "numeric",
											month: "numeric",
											year: "numeric",
										})}
									</Text>
								) : (
									<Text pt="2" fontSize="sm">
										-
									</Text>
								)}
							</Box>
							<Box>
								<Heading size="sm" textTransform="uppercase">
									Copias Iniciais
								</Heading>
								{machineContractDetails ? (
									<>
										<Text pt="2" fontSize="sm">
											Preto:{" "}
											{
												machineContractDetails.initialCountsBlack
											}
										</Text>
										<Text pt="2" fontSize="sm">
											Cor:{" "}
											{
												machineContractDetails.initialCountsColor
											}
										</Text>
									</>
								) : (
									<Text pt="2" fontSize="sm">
										-
									</Text>
								)}
							</Box>
							<Box>
								<Heading size="sm" textTransform="uppercase">
									A pagar
								</Heading>
								{contract && machineContractDetails ? (
									<Flex>
										<Box>
											<Text pt="2" fontSize="sm">
												Mensalidade:{" "}
												{
													machineContractDetails.monthlyFee
												}{" "}
												€
											</Text>
											<Text pt="2" fontSize="sm">
												Preço Preto:{" "}
												{
													machineContractDetails.blackCopyUnitPrice
												}{" "}
												€
											</Text>
											<Text pt="2" fontSize="sm">
												Preço Cor:{" "}
												{
													machineContractDetails.colorCopyUnitPrice
												}{" "}
												€
											</Text>
										</Box>
									</Flex>
								) : (
									<Text pt="2" fontSize="sm">
										-
									</Text>
								)}
							</Box>
						</Stack>
						<Stack spacing="4" flex="1 1 auto">
							<Heading size="sm" textTransform="uppercase">
								Descontos
							</Heading>
							{discounts &&
								discounts.map((discount: Discount) => (
									<Box key={discount.id}>
										<Flex
											direction="row"
											align="flex-start"
										>
											<Heading size="sm" mr={1}>
												{getDiscountTypeValue(
													discount.type
												)}
											</Heading>
											{/* {discount.usageDate && (
												<Text
													fontSize="sm"
													color="gray.600"
												>
													(
													{formatDateMonth(
														new Date(
															discount.usageDate
														)
													)}
													)
												</Text>
											)} */}
											<Text
												fontSize="sm"
												color="gray.600"
											>
												(
												{formatDateMonthAndYear(
													new Date(
														discount.startDate
													)
												)}{" "}
												/{" "}
												{formatDateMonthAndYear(
													new Date(discount.endDate)
												)}
												)
											</Text>
										</Flex>

										<Text pt="2" fontSize="sm">
											Desconto Preto:{" "}
											{discount.blackCopyDiscountAmount}{" "}
											(-{discount.usedBlackCopies})
										</Text>
										<Text pt="2" fontSize="sm">
											Desconto Cor:{" "}
											{discount.colorCopyDiscountAmount}{" "}
											(-{discount.usedColorCopies})
										</Text>
										<Divider mt={4} />
									</Box>
								))}
						</Stack>
					</Stack>
				</CardBody>
			</Card>
		</Container>
	);
};

export default ContractCard;

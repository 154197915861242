import React from "react";
import { Button, Flex } from "@chakra-ui/react";

// Define props for the component
type FormNavigationProps = {
	maxSteps: number;
	currentIndex: number;
	isStepValid?: boolean;
	onClickNext: () => void;
	onClickBack: () => void;
	onSubmit: () => void;
};

const FormNavigation: React.FC<FormNavigationProps> = ({
	maxSteps,
	currentIndex,
	isStepValid = true,
	onClickNext,
	onClickBack,
	onSubmit,
}) => {
	const isFirstStep = currentIndex === 0;
	const isLastStep = currentIndex === maxSteps - 1;

	return (
		<Flex
			justifyContent={!isFirstStep ? "space-between" : "flex-end"}
			w={"100%"}
			mt={10}
		>
			{!isFirstStep && (
				<Button variant={"outline"} onClick={onClickBack}>
					Anterior
				</Button>
			)}

			{isLastStep ? (
				<Button
					// isLoading={isSubmitting}
					// loadingText="A submeter"
					colorScheme="red"
					variant="outline"
					type="button"
					onClick={onSubmit}
					isDisabled={!isStepValid}
				>
					Submeter
				</Button>
			) : (
				<Button onClick={onClickNext} isDisabled={!isStepValid}>
					Seguinte
				</Button>
			)}
		</Flex>
	);
};

export default FormNavigation;

import {
	Container,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Radio,
	RadioGroup,
	Stack,
	VStack,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

const CustomerStep0 = () => {
	const { register, formState, control } = useFormContext();

	return (
		<Container maxW={"70%"}>
			<VStack spacing={6} align="flex-start">
				<FormControl
					isInvalid={!!formState.errors?.name?.message}
					isRequired
				>
					<FormLabel>Nome</FormLabel>
					<Input type="text" {...register("name")} />
					<FormErrorMessage>
						{formState.errors?.name?.message?.toString()}
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isInvalid={!!formState.errors?.email?.message}
					isRequired
				>
					<FormLabel>Email</FormLabel>
					<Input type="email" {...register("email")} />
					<FormErrorMessage>
						{formState.errors?.email?.message?.toString()}
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isInvalid={!!formState.errors?.telephone?.message}
					isRequired
				>
					<FormLabel>Telefone</FormLabel>
					<Input type="number" {...register("telephone")} />
					<FormErrorMessage>
						{formState.errors?.telephone?.message?.toString()}
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isInvalid={!!formState.errors?.nif?.message}
					isRequired
				>
					<FormLabel>NIF</FormLabel>
					<Input type="number" {...register("nif")} />
					<FormErrorMessage>
						{formState.errors?.nif?.message?.toString()}
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isInvalid={!!formState.errors?.sageId?.message}
					isRequired
				>
					<FormLabel>ID Sage</FormLabel>
					<Input type="number" {...register("sageId")} />
					<FormErrorMessage>
						{formState.errors?.sageId?.message?.toString()}
					</FormErrorMessage>
				</FormControl>
				<FormControl
					isInvalid={!!formState.errors?.active?.message}
				>
					<FormLabel>Ativo</FormLabel>
					<Controller
						control={control}
						name="active"
						render={({ field }) => (
							<RadioGroup {...field} defaultValue="true">
								<Stack spacing={5} direction="row">
									<Radio
										colorScheme="green"
										value="true"
										onChange={() => field.onChange("true")}
									>
										Sim
									</Radio>
									<Radio
										colorScheme="red"
										value="false"
										onChange={() =>
											field.onChange("false")
										}
									>
										Não
									</Radio>
								</Stack>
							</RadioGroup>
						)}
					/>
					<FormErrorMessage>
						{formState.errors?.active?.message?.toString()}
					</FormErrorMessage>
				</FormControl>
			</VStack>
		</Container>
	);
};

export default CustomerStep0;

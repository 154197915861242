import { GridItem, SimpleGrid } from "@chakra-ui/react";
import UserLoggedGridLayout from "../../layouts/UserLoggedGridLayout";
import ClientsStat from "../../features/adminDashboard/ClientsStat";
import CountsOverviewList from "../../features/adminDashboard/CountsOverviewList";
import {
	useGetMissingCountsQuery,
	useGetSubmittedCountsQuery,
} from "../../api/copyCountsApi";

const DashboardPage = () => {
	const { data: missingCounts = [], isLoading: missingCountsIsLoading } = useGetMissingCountsQuery();
	const { data: submittedCounts = [], isLoading: submittedCountsIsLoading } = useGetSubmittedCountsQuery();

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 2 }}>
				<ClientsStat />
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<SimpleGrid spacing={4} templateColumns="repeat(3, 1fr)">
					<CountsOverviewList
						data={missingCounts}
						title="Contagens em falta"
						color="#ffc154"
						isLoading={missingCountsIsLoading}
					/>
					<CountsOverviewList
						data={submittedCounts}
						title="Contagens submetidas"
						color="#36c2f3"
						isLoading={submittedCountsIsLoading}
					/>
				</SimpleGrid>
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default DashboardPage;

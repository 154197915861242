import * as Yup from "yup";

export const CustomerFormSchema = Yup.object().shape({
	id: Yup.number().required().default(0),
	name: Yup.string().required("Nome é obrigatório"),
	email: Yup.string()
		.email("Email inválido")
		.required("Email é obrigatório"),
	telephone: Yup.string()
		.required("Telefone é obrigatório")
		.matches(/^[0-9]+$/, "Telefone deve conter apenas números")
		.length(9, "Telefone inválido"),
	nif: Yup.string()
		.required("NIF é obrigatório")
		.matches(/^[0-9]+$/, "NIF deve conter apenas números")
		.length(9, "NIF inválido"),
	sageId: Yup.string()
		.required("Id sage é obrigatório")
		.matches(/^[0-9]+$/, "Sage ID deve conter apenas números"),
	active: Yup.string(),
	offices: Yup.array().of(
		Yup.object().shape({
			id: Yup.number().required().default(0),
			name: Yup.string().required("Nome é obrigatório"),
			email: Yup.string()
				.email("Email inválido")
				.required("Email é obrigatório"),
			address: Yup.string().required("Endereço é obrigatório"),
			postalCode: Yup.string().required("Codigo postal é obrigatório"),
			location: Yup.string().required("Localização é obrigatória"),
			telephone: Yup.string()
				.matches(/^[0-9]+$/, "Apenas números")
				.required("Telefone é obrigatório")
				.length(9, "Telefone inválido"),
			latitude: Yup.string().nullable(),
			longitude: Yup.string().nullable(),
			mainOffice: Yup.string(),
			machines: Yup.array().of(
				Yup.object().shape({
					id: Yup.number().required().default(0),
					typeId: Yup.string().required("Tipo é obrigatório"),
					serialNumber: Yup.string().required(
						"Número de Série é obrigatório"
					),
					brandId: Yup.string().required("Marca é obrigatória"),
					modelId: Yup.string().required("Modelo é obrigatório"),
					active: Yup.boolean(),
					sageId: Yup.string()
						.required("Id sage é obrigatório")
						.matches(
							/^[0-9]+$/,
							"ID sage deve conter apenas números"
						),
					officeId: Yup.number().required(
						"Escolher um escritório é obrigatório"
					),
				})
			),
		})
	),
	users: Yup.array().of(
		Yup.object().shape({
			id: Yup.number().required().default(0),
			name: Yup.string().required("Nome é obrigatório"),
			email: Yup.string()
				.email("Email inválido")
				.required("Email é obrigatório"),
		})
	),
});

export type CustomerFormType = Yup.InferType<typeof CustomerFormSchema>;

import React from "react";
import { Select } from "@chakra-ui/react";

interface YearSelectorProps {
	onYearSelect: (year: number) => void;
    selectRef: React.RefObject<HTMLSelectElement>;
}

const YearSelector: React.FC<YearSelectorProps> = ({ onYearSelect, selectRef }) => {
	const currentYear = new Date().getFullYear();
	const years = Array.from(
		{ length: currentYear - 2000 + 1 },
		(_, index) => currentYear - index
	);

	return (
		<Select
			placeholder="Selecione o ano"
			mt={8}
			onChange={(e) => onYearSelect(parseInt(e.target.value, 10))}
            ref={selectRef}
		>
			{years.map((year) => (
				<option key={year} value={year}>
					{year}
				</option>
			))}
		</Select>
	);
};

export default YearSelector;

import { apiSlice } from "./apiSlice";

interface ClientCountStat {
	totalClients: number;
	// previousMonthStart: number;
	// netChange: number;
}

export const statsApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getClientCountStats: build.query<ClientCountStat, void>({
			query: () => "/stats/clients/count",
		}),
	}),
});

// export react hook
export const { useGetClientCountStatsQuery } = statsApi;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { authApi } from "../api/authApi";
import { Role, ApiRefreshResponse } from "../utils/constants";
import {
	retrieveAndDecrypt,
	removeFromCookies,
	encryptAndStore,
} from "../utils/sessionStorageEncryption";
import { decodeToken } from "../utils/tokenUtils";

export interface AuthState {
	isAuthenticated: boolean;
	role: Role;
	email: string | null;
	name: string | null;
	clientId: number | null;
}

const defaultInitialState: AuthState = {
	isAuthenticated: false,
	role: Role.Guest,
	email: null,
	name: null,
	clientId: null,
};

const getInitialState = (): AuthState => {
	const authStored = retrieveAndDecrypt("auth");

	if (authStored) return authStored;
	else return defaultInitialState;
};

// Initialize the state using the computed value
const initialState = getInitialState();

const authSlice = createSlice({
	name: "auth",
	initialState, // Assign initial state
	reducers: {
		refreshAuth: (state, action: PayloadAction<ApiRefreshResponse>) => {
			completeLogin(state, action.payload);
		},
		logout: (state) => {
			state.isAuthenticated = false;
			state.role = Role.Guest;
			removeFromCookies("auth");
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			authApi.endpoints.login.matchFulfilled,
			(state, { payload }) => {
				completeLogin(state, payload);
			}
		);
		builder.addMatcher(
			authApi.endpoints.login.matchRejected,
			() => defaultInitialState // Use the defaultInitialState here
		);

		builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
			removeFromCookies("auth");
			return defaultInitialState; // Reset state to default
		});

		builder.addMatcher(
			authApi.endpoints.register.matchFulfilled,
			(state, { payload }) => {
				completeLogin(state, payload);
			}
		);
		builder.addMatcher(
			authApi.endpoints.register.matchRejected,
			() => defaultInitialState // Use the default initial state
		);
	},
});

export const { refreshAuth, logout } = authSlice.actions;
export default authSlice.reducer;

// Helper function to complete the login process
const completeLogin = (state: AuthState, payload: { accessToken: string }) => {
	const decoded_data = decodeToken(payload.accessToken);
	console.debug("Refresh auth decoded_data: ", decoded_data);

	if (decoded_data) {
		state.role = decoded_data.role;
		state.isAuthenticated = true;
		state.email = decoded_data.email;
		state.name = decoded_data.name;
		state.clientId = decoded_data.clientId;
		encryptAndStore("auth", state);
	}
};

import { skipToken } from "@reduxjs/toolkit/query";
import UserLoggedGridLayout from "../layouts/UserLoggedGridLayout";
import { useGetClientByIdQuery } from "../api/clientApi";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	Box,
	Flex,
	Badge,
	AccordionIcon,
	AccordionPanel,
	Text,
	GridItem,
	SimpleGrid,
	Heading,
} from "@chakra-ui/react";
import MachineCard from "../components/customer/MachineCard";
import { useAppSelector } from "../app/hooks";
import { useGetMissingCountsByClientIdQuery } from "../api/copyCountsApi";

const CustomerHomePage = () => {
	const { clientId } = useAppSelector((state) => state.auth);

	const { data: client } = useGetClientByIdQuery(
		clientId
			? {
					id: clientId,
					includeOffices: true,
					includeMachines: true,
			  }
			: skipToken
	);

	const { data: missingCounts } = useGetMissingCountsByClientIdQuery(
		clientId
			? {
					clientId,
			  }
			: skipToken
	);

	return (
		<UserLoggedGridLayout>
			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Heading>Bem vindo</Heading>
			</GridItem>
			{/* <p>alerts</p>
			<p>tipo faturação</p> */}

			<GridItem colSpan={{ base: 4, md: 10 }}>
				<Accordion allowToggle defaultIndex={0} mt={10}>
					{client?.offices?.map((office, index) => {
						const { name, machines = [] } = office;

						return (
							<AccordionItem key={index}>
								<AccordionButton>
									<Box as="span" flex="1">
										<Flex alignItems={"center"} gap={5}>
											<Text fontSize={"xl"}>{name}</Text>
											{missingCounts?.machines.some(
												(count) =>
													machines.some(
														(machine) =>
															machine.id ===
															count.id
													)
											) && (
												<Badge colorScheme="yellow">
													Contagem em falta
												</Badge>
											)}
										</Flex>
									</Box>
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel pb={4}>
									<SimpleGrid
										spacing={4}
										templateColumns="repeat(3, 1fr)"
									>
										{machines.map((machine) => {
											return (
												<MachineCard
													key={machine.id}
													machine={machine}
												/>
											);
										})}
									</SimpleGrid>
								</AccordionPanel>
							</AccordionItem>
						);
					})}
				</Accordion>
			</GridItem>
		</UserLoggedGridLayout>
	);
};

export default CustomerHomePage;

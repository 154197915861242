import { apiSlice } from "./apiSlice";

export interface CountsBillingResponse {
	blackBilling: number;
	colorBilling: number;
	totalBilling: number;
	isComplete: boolean;
	billingDate: {
		startDate: string;
		endDate: string;
	};
}

export const billingApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getCountsBilling: build.query<CountsBillingResponse, number>({
			query: (contractId) => `/billing/${contractId}/counts`,
			providesTags: (result, _error, contractId) =>
				result
					? [
							{ type: "CountsBilling", id: "LIST" },
							{ type: "CountsBilling", id: contractId },
					  ]
					: [],
		}),
	}),
});

export const { useGetCountsBillingQuery } = billingApi;

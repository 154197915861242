import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Divider,
	Flex,
	Heading,
	List,
	ListItem,
	Stack,
	Text,
} from "@chakra-ui/react";
import { ClientMachineCounts } from "../../api/copyCountsApi";
import { formatMachineName } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import TextWithLabel from "../../components/TextWithLabel";

interface CountsOverviewListProps {
	data: ClientMachineCounts[];
	title: string;
	color: string;
	isLoading: boolean;
}

const CountsOverviewList = ({
	data,
	title,
	color,
	isLoading,
}: CountsOverviewListProps) => {
	const navigate = useNavigate();

	return (
		<Card borderTop={`6px solid ${color}`} h={"490px"}>
			<CardHeader>
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Heading size={"lg"}>{title}</Heading>
					<Text fontSize="sm" color="gray.600">
						({data.length})
					</Text>
				</Flex>
				<Divider pt={3} />
			</CardHeader>
			<CardBody overflow={"auto"}>
				{isLoading ? (
					<Loading fillViewPort={false} />
				) : (
					<Box>
						<Accordion allowToggle>
							{data.map(({ client, machines }, index) => (
								<AccordionItem key={index}>
									{({ isExpanded }) => (
										<>
											<h2>
												<AccordionButton
													_expanded={{
														bg: "#ECECEC",
													}}
												>
													<Box
														flex="1"
														textAlign="left"
													>
														{client.name}
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel
												pb={4}
												bg={
													isExpanded ? "#ECECEC" : ""
												}
											>
												<Box
													p={3}
													bg={"white"}
													borderRadius={5}
												>
													<Flex
														pb={5}
														justifyContent={
															"space-between"
														}
														flexWrap={"wrap"}
														gap={5}
													>
														<TextWithLabel
															text={client.email}
															label="Email"
														/>
														<TextWithLabel
															text={
																client.telephone
															}
															label="Telefone"
														/>
													</Flex>
													<Stack gap={1}>
														<Text
															fontSize="sm"
															color="gray.600"
														>
															Máquinas
														</Text>
														<List>
															{machines.map(
																(machine) => (
																	<ListItem
																		key={
																			machine.id
																		}
																		fontWeight={500}
																	>
																		{formatMachineName(
																			machine
																		)}
																	</ListItem>
																)
															)}
														</List>
													</Stack>
													<Button
														size={"xs"}
														mt={3}
														onClick={() =>
															navigate(
																`/admin/clients/${client.id}/details`
															)
														}
													>
														Detalhes
													</Button>
												</Box>
											</AccordionPanel>
										</>
									)}
								</AccordionItem>
							))}
						</Accordion>
					</Box>
				)}
			</CardBody>
		</Card>
	);
};

export default CountsOverviewList;

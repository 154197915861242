import Select from "react-select";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ContractFormType } from "./ContractValidationSchemas";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import {
	SimpleGrid,
	Card,
	CardBody,
	Flex,
	FormControl,
	FormLabel,
	FormErrorMessage,
	CardFooter,
	IconButton,
	InputGroup,
	InputRightElement,
	NumberInput,
	NumberInputField,
} from "@chakra-ui/react";
import { useGetOfficesAndMachinesByClientIdQuery } from "../../api/officeApi";
import { contractType } from "./ContractFormConfig";
import { ContractType } from "../../api/contractApi";

interface ContractStep1Props {
	clientId: number;
}

const ContractStep1 = ({ clientId }: ContractStep1Props) => {
	const { data: officesWithMachines } =
		useGetOfficesAndMachinesByClientIdQuery(clientId);

	const {
		control,
		register,
		getValues,
		setValue,
		formState: { errors },
	} = useFormContext<ContractFormType>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "machinesContractDetails",
		keyName: "_id",
	});

	return (
		<SimpleGrid spacing={4} templateColumns="repeat(3, 1fr)">
			{fields.map((field, index) => (
				<Card key={field._id}>
					<CardBody>
						<Flex flexDirection={"column"} gap={3}>
							<FormControl
								isInvalid={
									!!errors?.machinesContractDetails?.[index]
										?.machineId?.message
								}
								isRequired
							>
								<FormLabel>Máquina</FormLabel>
								<Select
									{...register(
										`machinesContractDetails.${index}.machineId` as const
									)}
									options={
										officesWithMachines?.flatMap(
											(office) =>
												office.machines?.map(
													({
														id,
														sageId,
														brand,
														model,
													}) => ({
														label: `${sageId} - ${brand.name} ${model.name}`,
														value: id.toString(),
													})
												) || []
										) || []
									}
									value={officesWithMachines
										?.flatMap(
											(office) =>
												office.machines?.map(
													({
														id,
														sageId,
														brand,
														model,
													}) => ({
														label: `${sageId} - ${brand.name} ${model.name}`,
														value: id.toString(),
													})
												) || []
										)
										.find(
											(office) =>
												office.value ===
												getValues(
													`machinesContractDetails.${index}.machineId`
												).toString()
										)}
									onChange={(option) =>
										setValue(
											`machinesContractDetails.${index}.machineId`,
											option?.value || "",
											{
												shouldValidate: true,
											}
										)
									}
									placeholder={"Escolha uma máquina"}
								/>
								<FormErrorMessage>
									{
										errors.machinesContractDetails?.[index]
											?.machineId?.message
									}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									!!errors?.machinesContractDetails?.[index]
										?.contractType?.message
								}
								isRequired
							>
								<FormLabel>Tipo de contrato</FormLabel>
								<Select
									{...register(
										`machinesContractDetails.${index}.contractType` as const
									)}
									options={contractType}
									value={contractType.find(
										(type) =>
											type.value ===
											(
												getValues(
													`machinesContractDetails.${index}.contractType`
												) || ""
											).toString()
									)}
									onChange={(option) =>
										setValue(
											`machinesContractDetails.${index}.contractType`,
											option!.value as ContractType,
											{
												shouldValidate: true,
											}
										)
									}
									placeholder={"Escolha um tipo"}
								/>
								<FormErrorMessage>
									{
										errors.machinesContractDetails?.[index]
											?.contractType?.message
									}
								</FormErrorMessage>
							</FormControl>
							<Flex gap={2}>
								<FormControl
									isInvalid={
										!!errors?.machinesContractDetails?.[
											index
										]?.initialCountsBlack?.message
									}
									isRequired
								>
									<FormLabel>
										Contagem Inicial Preto
									</FormLabel>
									<NumberInput>
										<NumberInputField
											{...register(
												`machinesContractDetails.${index}.initialCountsBlack` as const
											)}
										/>
									</NumberInput>
									<FormErrorMessage>
										{
											errors.machinesContractDetails?.[
												index
											]?.initialCountsBlack?.message
										}
									</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={
										!!errors?.machinesContractDetails?.[
											index
										]?.initialCountsColor?.message
									}
									isRequired
								>
									<FormLabel>Contagem Inicial Cor</FormLabel>
									<NumberInput>
										<NumberInputField
											{...register(
												`machinesContractDetails.${index}.initialCountsColor` as const
											)}
										/>
									</NumberInput>
									<FormErrorMessage>
										{
											errors.machinesContractDetails?.[
												index
											]?.initialCountsColor?.message
										}
									</FormErrorMessage>
								</FormControl>
							</Flex>
							<Flex gap={2}>
								<FormControl
									isInvalid={
										!!errors?.machinesContractDetails?.[
											index
										]?.blackCopyUnitPrice?.message
									}
									isRequired
								>
									<FormLabel>Preço Cópia Preto</FormLabel>
									<InputGroup>
										<NumberInput w={"100%"}>
											<NumberInputField
												{...register(
													`machinesContractDetails.${index}.blackCopyUnitPrice` as const
												)}
											/>
										</NumberInput>
										<InputRightElement
											pointerEvents="none"
											color="gray.300"
											fontSize="1em"
										>
											€
										</InputRightElement>
									</InputGroup>
									<FormErrorMessage>
										{
											errors.machinesContractDetails?.[
												index
											]?.blackCopyUnitPrice?.message
										}
									</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={
										!!errors?.machinesContractDetails?.[
											index
										]?.colorCopyUnitPrice?.message
									}
									isRequired
								>
									<FormLabel>Preço Cópia Cor</FormLabel>
									<InputGroup>
										<NumberInput w={"100%"}>
											<NumberInputField
												{...register(
													`machinesContractDetails.${index}.colorCopyUnitPrice` as const
												)}
											/>
										</NumberInput>
										<InputRightElement
											pointerEvents="none"
											color="gray.300"
											fontSize="1em"
										>
											€
										</InputRightElement>
									</InputGroup>
									<FormErrorMessage>
										{
											errors.machinesContractDetails?.[
												index
											]?.colorCopyUnitPrice?.message
										}
									</FormErrorMessage>
								</FormControl>
							</Flex>
							<FormControl
								isInvalid={
									!!errors?.machinesContractDetails?.[index]
										?.monthlyFee?.message
								}
								isRequired
							>
								<FormLabel>Mensalidade</FormLabel>
								<InputGroup>
									<NumberInput w={"100%"} display={"flex"}>
										<NumberInputField
											{...register(
												`machinesContractDetails.${index}.monthlyFee` as const
											)}
										/>
									</NumberInput>
									<InputRightElement
										pointerEvents="none"
										color="gray.300"
										fontSize="1em"
									>
										€
									</InputRightElement>
								</InputGroup>
								<FormErrorMessage>
									{
										errors.machinesContractDetails?.[index]
											?.monthlyFee?.message
									}
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</CardBody>
					<CardFooter>
						{fields.length > 0 && (
							<IconButton
								aria-label="Remove object"
								icon={<MinusIcon />}
								variant="outline"
								onClick={() => remove(index)}
							/>
						)}
					</CardFooter>
				</Card>
			))}
			<Flex alignItems={"center"}>
				<IconButton
					aria-label="Add object"
					icon={<AddIcon />}
					onClick={() =>
						append({
							id: 0,
							contractType: "",
							initialCountsBlack: "",
							initialCountsColor: "",
							blackCopyUnitPrice: "",
							colorCopyUnitPrice: "",
							monthlyFee: "",
							contractId: getValues("contract.id"),
							machineId: "",
						})
					}
				/>
			</Flex>
		</SimpleGrid>
	);
};

export default ContractStep1;

import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	useToast,
	Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Role } from "../utils/constants";
import { getErrorMessage, isAdmin, isClient } from "../utils/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { decodeToken } from "../utils/tokenUtils";
import { useRegisterMutation } from "../api/authApi";
import { useAppSelector } from "../app/hooks";

const RegisterPage: React.FC = () => {
	const navigate = useNavigate();
	const toast = useToast();

	const { token } = useParams();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { isAuthenticated, role } = useAppSelector((state) => state.auth);

	const [register, { isLoading, isError, isSuccess, error }] =
		useRegisterMutation();

	// Redirect if user is logged in or after the register
	useEffect(() => {
		if (isAuthenticated) {
			if (role === Role.Client) navigate("/client");
			else if (role === Role.Admin) navigate("/admin");
		}
	}, [isAuthenticated, navigate, role]);

	// Read token
	useEffect(() => {
		let decoded_data;
		if (token) {
			decoded_data = decodeToken(token);
			setEmail(decoded_data?.email || "");
			setName(decoded_data?.name || "");
		}
	}, [token]);

	useEffect(() => {
		if (error) {
			if ("status" in error) {
				const errMsg =
					"error" in error
						? error.error
						: JSON.stringify(error.data);

				toast({
					status: "error",
					title: "Erro",
					description: errMsg,
					isClosable: true,
				});
			}
		}
	}, [error, isError, isLoading, isSuccess, toast]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!token) return;

		const promise = register({ name, email, password, token }).unwrap();
		toast.promise(promise, {
			loading: {
				title: "A fazer login",
				description: "Aguarde enquanto processamos o seu pedido.",
			},
			success: {
				title: "Registo efetuado",
			},
			error: (err: Error) => {
				const errorMessage = getErrorMessage(
					err as FetchBaseQueryError | SerializedError
				);

				return {
					title: "Erro ao registar",
					description: errorMessage,
				};
			},
		});
		promise
			.then((data) => {
				const decoded_data = decodeToken(data.accessToken);

				if (!decoded_data) return;

				const role = decoded_data.role;
				if (isAdmin(role)) {
					navigate("/admin");
				} else if (isClient(role)) {
					navigate("/client");
				}
			})
			.catch(() => {});
	};

	return (
		<Flex
			minH={"100vh"}
			align={"center"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}
		>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Image
						w={{ base: "320px", md: "600px" }}
						src="/assets/images/logo.png"
						alt="Assisteprint Logo"
						paddingBottom={"40px"}
					/>
					<Heading fontSize={"3xl"}>Registe a sua conta</Heading>
					{isError && (
						<Alert status="error" mt={"40px"}>
							<AlertIcon />
							<AlertTitle>Erro ao iniciar sessão</AlertTitle>
						</Alert>
					)}
				</Stack>
				<Box
					rounded={"lg"}
					bg={useColorModeValue("white", "gray.700")}
					boxShadow={"lg"}
					p={8}
				>
					<form onSubmit={handleSubmit}>
						<Stack spacing={4}>
							<FormControl id="name" isRequired>
								<FormLabel>Name</FormLabel>
								<Input
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</FormControl>
							<FormControl id="email" isRequired>
								<FormLabel>Email</FormLabel>
								<Input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>
							<FormControl id="password" isRequired>
								<FormLabel>Password</FormLabel>
								<Input
									type="password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</FormControl>
							<Stack spacing={10} mt={5}>
								<Button type="submit">Registar</Button>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
		</Flex>
	);
};

export default RegisterPage;

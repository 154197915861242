import { jwtDecode } from "jwt-decode";
import { TokenInfo } from "./constants";

export function decodeToken(token: string): TokenInfo | null {
  try { 
    const tokenInfo: TokenInfo = jwtDecode(token) as TokenInfo;
    return tokenInfo;
  } catch (error) {
    console.error('Error decoding token: ', error);
    return null;
  }
}